import { APP_CONFIG_STAGES, AppConfigService } from './shared-util-app-config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MockAppConfigService implements AppConfigService {
  basePath: string | Observable<string> = '';
  version = '1.0.0';
  state: APP_CONFIG_STAGES = 'PROD';
  clientId = 'mock-client-id';
  productName?: string = 'mock-product-name';
  logoPaths?: { light: string; dark: string; icon?: string } = {
    light: 'mock-light-logo-path',
    dark: 'mock-dark-logo-path',
  };
  disablePermissionChecks?: boolean = true;
  disableFreshChat?: boolean = true;

  get isProd(): boolean {
    return true;
  }
}

export const provideMockAppConfigService = () => ({
  provide: AppConfigService,
  useClass: MockAppConfigService,
});
