import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ParticipationCriteria } from '@tremaze/shared/feature/participation-criteria/types';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { Subject } from 'rxjs';
import { NotificationService } from '@tremaze/shared/notification';
import { FocusMonitor } from '@angular/cdk/a11y';
import { NgControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ParticipationCriteriaSelectionService } from './participation-criteria-selection.service';

@Directive({
  selector: 'button[tremazeParticipationCriteriaSelectionButton]',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ParticipationCriteriaSelectionInputDirective,
    },
  ],
})
export class ParticipationCriteriaSelectionInputDirective
  implements
    MatFormFieldControl<ParticipationCriteria[]>,
    OnDestroy,
    DoCheck,
    ControlValueAccessor<ParticipationCriteria[]>
{
  static nextId = 0;
  onChange?: (value: ParticipationCriteria[]) => void;
  onTouched?: () => void;
  readonly autofilled: boolean;
  readonly controlType: string;
  readonly disabled: boolean;
  errorState: boolean;
  readonly required: boolean;
  readonly shouldLabelFloat: boolean;
  readonly stateChanges = new Subject<void>();
  @HostBinding()
  id =
    `tremaze-time-input-${ParticipationCriteriaSelectionInputDirective.nextId++}`;
  @ViewChild('inputElement')
  private inputElementElementRef: ElementRef<HTMLInputElement>;

  constructor(
    private notificationService: NotificationService,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private selectionService: ParticipationCriteriaSelectionService,
    @Optional() private componentRef: MatButton,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this._focused = !!origin;
      this.stateChanges.next(null);
    });
  }

  private _mandatory: boolean;

  @Input()
  get mandatory(): boolean {
    return this._mandatory;
  }

  set mandatory(value: boolean) {
    this._mandatory = coerceBooleanProperty(value);
  }

  get empty(): boolean {
    return !this.value?.length;
  }

  private _focused: boolean;

  set focused(value: boolean) {
    this._focused = value;
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next(null);
  }

  private _value: ParticipationCriteria[] | null;

  @Input()
  get value(): ParticipationCriteria[] | null {
    return this._value;
  }

  set value(value: ParticipationCriteria[] | null) {
    const amount = (value || []).length;
    const amountString = amount === 0 ? 'Keine' : amount.toString();
    this.elRef.nativeElement.innerHTML = `${amountString} ${
      this.mandatory ? 'verpflichtende' : 'optionale'
    } Zusatzangabe${amount === 1 ? '' : 'n'}`;
    this._value = value;
  }

  @HostListener('click')
  async onClick() {
    const res = await this.selectionService
      .selectCriteria(this.value, this.mandatory)
      .toPromise();
    if (!!res) {
      this.writeValue(res);
    }
  }

  writeValue(value: ParticipationCriteria[]): void {
    this.value = value;
    this.stateChanges.next(null);
    this.onChangeCallback(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next(null);
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  private onTouchedCallback: () => void = () => {};

  private onChangeCallback: (_: any) => void = () => {};
}
