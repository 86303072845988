<h1 matDialogTitle>CSV-Vorlage bearbeiten</h1>
<mat-dialog-content>
  <button mat-stroked-button color="primary" (click)="onClickImportCSVFile()">
    CSV-Datei importieren
  </button>
  <div class="w-100" style="margin-top: 20px">
    <mat-form-field class="w-100">
      <mat-label>Vorlage</mat-label>
      <textarea style="height: 400px" matInput [(ngModel)]="value"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button color="accent" (click)="onClickSave()">
    Speichern
  </button>
</mat-dialog-actions>

<input
  hidden
  #input
  (change)="onFileSelected($event)"
  accept=".csv"
  type="file"
/>
