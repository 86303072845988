import { TremazeDate } from '@tremaze/shared/util-date';

export type CustomFormExportSchemaColumn = {
  columnName: string;
  sort: number;
} & (
  | {
      formFieldIdentifier: string;
    }
  | {
      otherDataIdentifier: string;
    }
  | {
      staticValue: string;
    }
);

export type CustomFormExportSchema = {
  delimiter: string;
  baseCSV?: string;
  headerRowIndex: number;
  columns: CustomFormExportSchemaColumn[];
  exportFilename?: string;
};

export type CustomFormExportSchemaPlaceholder = {
  name: string;
  displayName: string;
};

export type CustomFormExportSchemaPlaceholders = {
  formFieldPlaceholders: {
    latest: {
      formVersionId: string;
      formVersionDate: TremazeDate;
      placeholders: CustomFormExportSchemaPlaceholder[];
    };
    other: CustomFormExportSchemaPlaceholders['formFieldPlaceholders']['latest'][];
  };
  otherPlaceholders: CustomFormExportSchemaPlaceholder[];
};
