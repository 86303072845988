import { inject, Injectable } from '@angular/core';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DocumentEditorService {
  private readonly _router = inject(Router);

  openPage({ fileStorage }: { fileStorage: FileStorage }) {
    const urlTree = this._router.createUrlTree(
      ['document-editor', fileStorage.id],
      {},
    );

    window.open(urlTree.toString(), '_blank');
  }
}
