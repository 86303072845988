<h2 mat-dialog-title>Berichtsvorlage bearbeiten</h2>
<div mat-dialog-content>
  <div style='display: flex; align-items: center; gap: 1rem'>
    <button mat-stroked-button color="accent" (click)="fileInput.click()"
            matTooltip="Datei auswählen">{{ buttonLabel }}
    </button>
    @if (pdfTemplateFormControl.value) {
      <button mat-icon-button color="accent" (click)="onClickEditPdfTemplate()" matTooltip="PDF-Vorlage bearbeiten">
        <tremaze-icon icon="lnr-clipboard-pencil"></tremaze-icon>
      </button>
      <button mat-icon-button color="warn" (click)="onClickDeletePdfTemplate()" matTooltip="PDF-Vorlage löschen">
        <tremaze-icon icon="lnr-trash2"></tremaze-icon>
      </button>
    }
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Schließen</button>
  <button mat-raised-button color="accent" (click)="onClickSubmitButton()" [disabled]='pdfTemplateFormControl.pristine'>Speichern</button>
</div>
<input type="file" #fileInput accept="application/pdf" (change)="onFileChanged($event)" hidden>
