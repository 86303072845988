import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { firstValueFrom } from 'rxjs';
import { SharedFeatureCustomFormsFeatureEditModule } from './shared-feature-custom-forms-feature-edit.module';
import { CustomFormEditService } from './edit.service';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NotificationService } from '@tremaze/shared/notification';
import { doOnError } from '@tremaze/shared/util/rxjs';

@Component({
  selector: 'tremaze-custom-form-csv-export-template-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    SharedFeatureCustomFormsFeatureEditModule,
    FormsModule,
  ],
  templateUrl: './custom-form-csv-export-template-dialog.component.html',
  styleUrl: './custom-form-csv-export-template-dialog.component.scss',
})
export class CustomFormCsvExportTemplateDialogComponent {
  private readonly _confirmationService = inject(ConfirmationService);
  private readonly _notificationService = inject(NotificationService);
  private readonly _formEditService = inject(CustomFormEditService);
  private readonly _formId = inject(MAT_DIALOG_DATA);
  private readonly _ref = inject(MatDialogRef);

  value?: string;

  @ViewChild('input', { static: true }) input?: ElementRef<HTMLInputElement>;

  constructor() {
    this._formEditService
      .getCSVExportTemplate(this._formId)
      .pipe(
        doOnError(() => {
          this._notificationService.showDefaultErrorNotification();
        }),
      )
      .subscribe((value) => {
        this.value = value;
      });
  }

  async onClickImportCSVFile(): Promise<void> {
    if (this.value?.length) {
      const confirmed = await firstValueFrom(
        this._confirmationService.askUserForConfirmation({
          title: 'CSV importieren',
          text: `Möchtest du eine neue CSV-Datei importieren? Die bisherige Vorlage wird dabei überschrieben.`,
        }),
      );

      if (!confirmed.confirmed) {
        return;
      }
    }

    this.input?.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      target.value = '';
      // read file
      const reader = new FileReader();
      reader.onload = () => {
        this.value = reader.result as string;
      };
      reader.readAsText(file);
    }
  }

  onClickSave(): void {
    this._formEditService
      .setCSVExportTemplate(this._formId, this.value ?? '')
      .pipe(
        doOnError(() => {
          this._notificationService.showDefaultErrorNotification();
        }),
      )
      .subscribe(() => {
        this._notificationService.showNotification(
          'CSV-Export-Vorlage erfolgreich gespeichert',
        );
        this._ref.close();
      });
  }
}
