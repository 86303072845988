import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import {
  Deserializable,
  staticImplements,
} from '@tremaze/shared/util-decorators';
import { Meta } from '@tremaze/shared/models';
import { Role } from '@tremaze/shared/permission/types';

@staticImplements<Deserializable<Tenant>>()
export class Tenant {
  constructor(
    readonly id?: string,
    public name?: string,
    public ccLink?: string,
    public dataPrivacy?: string,
    public eventAgreement?: string,
    public guestRegInfo?: string,
    public imprint?: string,
    public logo?: FileStorage,
    public favicon?: FileStorage,
    public titleImage?: FileStorage,
    public mailImprint?: string,
    public minRegAge?: number,
    public totalLicenses?: number,
    public usedLicenses?: number,
    public tenantEventFiles: FileStorage[] = [],
    public accessibilityText?: string,
    public description?: string,
    public teaserText?: string,
    public teaserImage?: FileStorage,
    public globalDefaultRoles?: Role[],
    public tenantWebsiteLink?: string,
    public fakeAccountsAreDefault = false,
    public appWelcomeText?: string,
    public deleteAccountRequestEmail?: string,
    public enableAnonymousRegistration?: boolean,
    public defaultForceDocumentationPeriodForEvents?: boolean,
  ) {}

  static deserialize(data): null | Tenant {
    return !data
      ? null
      : new Tenant(
          data.tenantId ?? data.id,
          data.name,
          data.ccLink,
          data.dataPrivacy,
          data.eventAgreement,
          data.guestRegInfo,
          data.imprint,
          FileStorage.deserialize(data.logo),
          FileStorage.deserialize(data.favicon),
          FileStorage.deserialize(data.titleImage),
          data.mailImprint,
          data.minRegAge,
          data.totalLicenses,
          data.usedLicenses,
          data.tenantEventFiles?.map(FileStorage.deserialize) || [],
          data.accessibilityText,
          data.description,
          data.teaserText,
          FileStorage.deserialize(data.teaserImage),
          data.globalDefaultRoles?.map(Role.deserialize),
          data.tenantWebsiteLink,
          data.fakeAccountsAreDefault ?? false,
          data.appWelcomeText,
          data.deleteAccountRequestEmail,
          data.enableAnonymousRegistration,
          data.defaultForceDocumentationPeriodForEvents,
        );
  }
}

@staticImplements<Deserializable<TenantProject>>()
export class TenantProject {
  constructor(
    public id: string,
    public image: FileStorage,
    public link: string,
  ) {}

  static deserialize(data) {
    return !data
      ? null
      : new TenantProject(
          data.id,
          FileStorage.deserialize(data.image),
          data.link,
        );
  }
}

@staticImplements<Deserializable<TenantNews>>()
export class TenantNews {
  constructor(
    public expiryDate: string,
    public id: string,
    public meta: Meta,
    public published: boolean,
    public text: string,
    public title: string,
    public titleImage: FileStorage,
  ) {}

  static deserialize(data: TenantNews): null | TenantNews {
    return !data
      ? null
      : new TenantNews(
          data.expiryDate,
          data.id,
          data.meta,
          data.published,
          data.text,
          data.title,
          FileStorage.deserialize(data.titleImage),
        );
  }
}
