import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { map, take } from 'rxjs/operators';
import { MSALAuthService } from './msal-auth-service';

@Injectable({ providedIn: 'root' })
export class MsalCanLoadGuard implements CanLoad {
  constructor(
    private ms: MSALAuthService,
    private authService: AuthV2Service,
    private router: Router,
  ) {}

  canLoad(): Observable<boolean | UrlTree> {
    return this.authService.hasActiveTenant$.pipe(
      take(1),
      map((r) => {
        if (!r) {
          return this.router.createUrlTree(['/login']);
        }
        return true;
      }),
    );
  }
}
