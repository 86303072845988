@if (isBlocked$ | async) {
  <div class='blocked-hint'>
    Dieses Dokument wird gerade von einer anderen Person bearbeitet und ist
    daher blockiert. Bitte warte, bis es wieder verfügbar ist oder komme
    später wieder.
  </div>
}
<mat-drawer-container>
  <mat-drawer-content>
    <ejs-documenteditorcontainer
      (toolbarClick)='onToolbarClick($event)'
      [currentUser]="(currentUserName$ | async) ?? ''"
      [documentEditorSettings]='{ printDevicePixelRatio: 5 }'
      [enableToolbar]='true'
      [toolbarItems]='toolbarItems'
      locale='de-DE'
      serviceUrl='https://wordprocessor.tagea.app/api/documenteditor/'
    ></ejs-documenteditorcontainer>
    @if (isLoading$ | async) {
      <div
        style='position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(255, 255, 255, 0.8); display: flex; justify-content: center; align-items: center;'
      >
        <mat-spinner></mat-spinner>
      </div>
    }

    <div class='bottom'>
      @if (canWrite$ | async) {
        <tremaze-autosave-display
          [lastSave]='lastModified'
          [isSaving]='isSaving$ | async'
        ></tremaze-autosave-display>
      }
      <span class='spacer'></span>
      <button (click)='print()' mat-button>Drucken</button>
      @if ((canWrite$ | async) && editorEnabled && saveFn) {
        <button (click)='onClickedSave()' mat-button [disabled]='isBlocked$ | async'>Speichern</button>
      }
      <button (click)='onClickedRequestClose()' mat-button>Schließen</button>
    </div>

  </mat-drawer-content>

  @if (placeholders) {
    <mat-drawer
      #sidenav
      [opened]='showFillableItems'
      mode='side'
      position='end'
    >
      <tremaze-placeholder-table (fill)='fillFields()' [placeholders]='placeholders' />
    </mat-drawer>
  }
</mat-drawer-container>
