import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  DocumentEditorContainerComponent,
  DocumentEditorContainerModule,
  PrintService,
  ToolbarItem,
  ToolbarService,
} from '@syncfusion/ej2-angular-documenteditor';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { CustomToolbarItemModel } from '@syncfusion/ej2-angular-pdfviewer';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { DocumentEditorWebsocketService } from './document-editor-websocket.service';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { AutosaveDisplayComponent } from './autosave-display.compontent';
import { TremazeDate } from '@tremaze/shared/util-date';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { blobToFile } from '@tremaze/shared/util-utilities';
import {
  filterNotNullOrUndefined,
  shareReplayWithRefCount,
} from '@tremaze/shared/util/rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { FileStoragePermissionsService } from '@tremaze/shared/feature/file-storage/feature/file-storage-permissions';
import { NotificationService } from '@tremaze/shared/notification';
import { Title } from '@angular/platform-browser';
import { Placeholder, PlaceholderTableComponent } from '@tremaze/placeholder';

const toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] = [
  'New',
  'Open',
  'Separator',
  'Undo',
  'Redo',
  'Separator',
  'Image',
  'Table',
  'Hyperlink',
  'Bookmark',
  'TableOfContents',
  'Separator',
  'Header',
  'Footer',
  'PageSetup',
  'PageNumber',
  'Break',
  'Separator',
  'Find',
  'Separator',
  'Comments',
  'TrackChanges',
  'Separator',
  'LocalClipboard',
  'RestrictEditing',
  'Separator',
  'FormFields',
  'UpdateFields',
];

@Component({
  selector: 'tremaze-document-editor',
  standalone: true,
  imports: [
    CommonModule,
    DocumentEditorContainerModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatCardModule,
    MatButton,
    AutosaveDisplayComponent,
    PlaceholderTableComponent,
  ],
  templateUrl: `./document-editor.component.html`,
  styleUrls: ['./document-editor.component.scss'],
  providers: [ToolbarService, PrintService, DocumentEditorWebsocketService],
})
export class DocumentEditorComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly _websocketService = inject(DocumentEditorWebsocketService);
  private readonly _http = inject(HttpClient);
  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _permissionsService = inject(FileStoragePermissionsService);
  private readonly _notificationService = inject(NotificationService);
  private readonly _cdRef = inject(ChangeDetectorRef);
  private readonly _title = inject(Title);

  readonly isBlocked$ = this._websocketService.isBlocked$;

  readonly openFillableItem = {
    prefixIcon: 'e-form-field',
    tooltipText: 'Platzhalter',
    text: 'Platzhalter',
    id: 'openFillableItem',
  };

  private _toolbarItems: (CustomToolbarItemModel | ToolbarItem)[] =
    toolbarItems;

  get toolbarItems() {
    return this._toolbarItems;
  }

  readonly currentUserName$ = inject(AuthV2Service).authenticatedUser$.pipe(
    map((u) => u.firstName + ' ' + u.lastName),
  );

  get proxyFixedDocumentUrl() {
    if (!this.documentUrl) {
      return;
    }
    return this.documentUrl.replace(
      'http://localhost:4200/api',
      'https://api.dev.cloud.tagea.app',
    );
  }

  private _file$ = new BehaviorSubject<FileStorage | null>(null);

  private _canWriteOverride = new BehaviorSubject<boolean | null>(null);

  readonly canWrite$ = this._canWriteOverride.pipe(
    switchMap((override) => {
      if (override !== null) {
        return of(override);
      }
      return this._file$.pipe(
        filterNotNullOrUndefined(),
        switchMap((file) => {
          return this._permissionsService
            .getPermissionForFile(file.id)
            .pipe(map((r) => r.includes('WRITE')));
        }),
      );
    }),
    distinctUntilChanged(),
    tap((r) => {
      if (r) {
        this._enableEditor();
      } else {
        this._disableEditor();
      }
    }),
    shareReplayWithRefCount(),
  );

  private _fileId?: string;

  @Input({ required: true }) set fileId(value: string | undefined) {
    this._fileId = value;
    this._websocketService.setFileId(value);
    if (value) {
      this._fileStorageService.getFileById(value).subscribe((file) => {
        this.lastModified =
          file.meta?.editDate ?? file?.meta?.insertDate ?? undefined;
        this.documentName = file.fileViewname;
        this._file$.next(file);
        this._title.setTitle(file.fileViewname + ' | Tagea');
      });
    }
  }

  private _enablePlaceholders = false;

  get enablePlaceholders(): boolean {
    return this._enablePlaceholders;
  }

  @Input({ transform: booleanAttribute }) set enablePlaceholders(
    value: boolean,
  ) {
    this._enablePlaceholders = value;
    if (value) {
      this._toolbarItems = [this.openFillableItem, ...toolbarItems];
    } else {
      this._toolbarItems = toolbarItems;
    }
  }

  @Input() placeholders: Placeholder[] | null = [];

  @Input({ transform: booleanAttribute }) withAutosave = false;

  @Input() saveFn?: (data: Blob) => Observable<void> = (data) => {
    const fileId = this._fileId;
    if (fileId) {
      const file = blobToFile(data, this.documentName, 'application/msword');
      return this._fileStorageService
        .overwriteFile(fileId, file)
        .pipe(map(() => void 0));
    }
    alert('No file id');
    return EMPTY;
  };

  private _documentUrl?: string;

  get documentUrl(): string | undefined {
    return this._documentUrl;
  }

  @Input()
  set documentUrl(value: string) {
    this._documentUrl = value;
    const proxyFixedDocumentUrl = this.proxyFixedDocumentUrl;
    if (proxyFixedDocumentUrl) {
      this.loadDocument(proxyFixedDocumentUrl);
    }
  }

  @Input() documentName = 'document.pdf';

  private _showFillableItems = false;

  get showFillableItems() {
    return this._showFillableItems;
  }

  @Input({ transform: booleanAttribute }) set canWrite(value: boolean) {
    this._canWriteOverride.next(value);
  }

  @Input() lastModified?: TremazeDate;

  private _isSaving$ = new BehaviorSubject<boolean>(false);

  get isSaving$() {
    return this._isSaving$;
  }

  private _requestCloseEvent$ = new Subject<void>();

  readonly requestClose = outputFromObservable(
    merge(this._websocketService.requestClose$, this._requestCloseEvent$),
  );

  @ViewChild(DocumentEditorContainerComponent, { static: true })
  documentEditor?: DocumentEditorContainerComponent;

  private _destroyed$ = new Subject<void>();
  readonly isLoading$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    if (!this._documentUrl) {
      this._file$
        .pipe(
          filterNotNullOrUndefined(),
          takeUntil(this._destroyed$),
          take(1),
          switchMap((file) =>
            this._fileStorageService.getFileDownloadURL(file),
          ),
          filterNotNullOrUndefined(),
          filter(() => !this._documentUrl),
          tap((url) => {
            this.documentUrl = url;
          }),
        )
        .subscribe();
    }

    this.canWrite$.subscribe();

    this._websocketService.isBlocked$
      .pipe(distinctUntilChanged())
      .subscribe((isBlocked) => {
        if (isBlocked) {
          this._disableEditor();
        } else {
          this._enableEditor();
        }
      });
  }

  ngAfterViewInit() {
    if (this.documentEditor) {
      (this.documentEditor.contentChange as EventEmitter<unknown>)
        .pipe(
          debounceTime(1500),
          takeUntil(this._destroyed$),
          map(() => this.getBlob()),
          filterNotNullOrUndefined(),
          switchMap((value) => value),
          filterNotNullOrUndefined(),
          tap(() => {
            this._isSaving$.next(true);
            this._cdRef.detectChanges();
          }),
          map((value) => this.saveFn?.(value)),
          filterNotNullOrUndefined(),
          switchMap((value: Observable<void>) =>
            value.pipe(
              tap(() => {
                this.lastModified = TremazeDate.getNow();
              }),
              finalize(() => {
                this._isSaving$.next(false);
                this._cdRef.detectChanges();
              }),
            ),
          ),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.isLoading$.complete();
    this._requestCloseEvent$.complete();
    this._file$.complete();
    this._canWriteOverride.complete();
    this._isSaving$.complete();
  }

  private _editorEnabled = true;

  get editorEnabled(): boolean {
    return this._editorEnabled;
  }

  private _disableEditor() {
    this._editorEnabled = false;
    if (this.documentEditor) {
      this.documentEditor.enableToolbar = false;
      this.documentEditor.restrictEditing = true;
    }
  }

  private _enableEditor() {
    this._editorEnabled = true;
    if (this.documentEditor && !this.documentEditor.enableToolbar) {
      this.documentEditor.enableToolbar = true;
      this.documentEditor.restrictEditing = false;
      const proxyFixedDocumentUrl = this.proxyFixedDocumentUrl;
      if (proxyFixedDocumentUrl) {
        this.loadDocument(proxyFixedDocumentUrl);
      }
    }
  }

  onToolbarClick(ev: { item: { id: string } }) {
    if (ev?.item?.id === 'openFillableItem') {
      this._showFillableItems = !this._showFillableItems;
    }
  }

  onClickedRequestClose() {
    this._requestCloseEvent$.next();
  }

  async onClickedSave() {
    if (this.saveFn && !this._isSaving$.value) {
      this._isSaving$.next(true);
      this._cdRef.detectChanges();
      const blob = await this.getBlob();
      if (blob) {
        this.saveFn(blob)
          .pipe(
            tap(() => {
              this.lastModified = TremazeDate.getNow();
              this._notificationService.showNotification(
                'Dokument gespeichert',
              );
            }),
            finalize(() => {
              this._isSaving$.next(false);
              this._cdRef.detectChanges();
            }),
          )
          .subscribe();
      } else {
        this._isSaving$.next(false);
        this._cdRef.detectChanges();
      }
    }
  }

  public print(): void {
    if (this.documentEditor) {
      let color: string | undefined;
      if (
        this.documentEditor.documentEditorSettings &&
        this.documentEditor!.documentEditorSettings!.formFieldSettings
      ) {
        color =
          this.documentEditor.documentEditorSettings.formFieldSettings
            .shadingColor;
        this.documentEditor.documentEditorSettings.formFieldSettings.shadingColor =
          '#ffffff';
      }
      setTimeout(() => {
        this.documentEditor!.documentEditor.print();
        if (color) {
          this.documentEditor!.documentEditorSettings!.formFieldSettings!.shadingColor =
            color;
        }
      });
    }
  }

  private loadDocument(url: string) {
    this.isLoading$.next(true);
    this._http
      .get(url, {
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache',
        },
      })
      .pipe(
        switchMap((r) => {
          // send the file back to api to convert it to .sfdt format
          const data = new FormData();
          data.append('file', r as any);
          return this._http.post<{ sfdt: string }>(
            '/syncfusion/import/word',
            data,
            {
              responseType: 'json',
            },
          );
        }),
        takeUntil(this._destroyed$),
        finalize(() => this.isLoading$.next(false)),
      )
      .subscribe((response) => {
        this.documentEditor!.documentEditor.open(response as any);
      });
  }

  async getBlob() {
    if (!this.documentEditor) {
      return;
    }
    const blob = await this.documentEditor.documentEditor.saveAsBlob('Docx');
    return new Blob([blob], { type: 'application/msword' });
  }

  async fillFields() {
    const _docEditor = this.documentEditor?.documentEditor;
    for (const variable of this.placeholders ?? []) {
      _docEditor?.search.findAll(variable.name, 'CaseSensitive');
      _docEditor?.search.searchResults.replaceAll(
        !variable.value || variable.value.length == 0 ? '-' : variable.value,
      );
    }
  }
}
