import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as TenantActions from './tenant.actions';
import {
  RemotePublicTenantDataSource,
  TenantDataSource,
} from '@tremaze/shared/feature/tenant/data-access';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import { TenantPartialState } from './tenant.reducer';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { DocumentRef } from '@tremaze/shared/util/dom/document';
import { AppConfigService } from '@tremaze/shared/util-app-config';

@Injectable({ providedIn: 'root' })
export class TenantEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.init),
      startWith({}),
      withLatestFrom(this.state),
      filter(([_, { tenant }]) => !tenant.loaded),
      mergeMap(() =>
        this.dataSource.getTenant().pipe(
          map((t) => TenantActions.loadTenantSuccess({ tenant: [t] })),
          catchError((error) => of(TenantActions.loadTenantFailure({ error }))),
        ),
      ),
    ),
  );

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.loadTenantProjects),
      withLatestFrom(this.state),
      mergeMap(() => this.publicDataSource.getTenantProjects()),
      map((projects) => TenantActions.loadTenantProjectsSuccess({ projects })),
    ),
  );

  constructor(
    private actions$: Actions,
    private state: Store<TenantPartialState>,
    private dataSource: TenantDataSource,
    private publicDataSource: RemotePublicTenantDataSource,
    private document: DocumentRef,
    private config: AppConfigService,
  ) {}
}
