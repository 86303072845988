import { CustomFormFeature } from '@tremaze/shared/feature/custom-forms/types';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Observable } from 'rxjs';

export interface CustomFormEditConfig {
  feature?: CustomFormFeature;
  institutions?: Observable<Institution[]> | Institution[];
  hideInstitutionSettings?: boolean;
  formId?: string;
}
