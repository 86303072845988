import { Injectable } from '@angular/core';
import { AuthV2Service } from '../../auth-service';
import {
  AuthenticatedUser,
  TokenInfo,
} from '@tremaze/shared/core/auth-v2/types';
import { Tenant } from '@tremaze/shared/feature/tenant/types';
import { Observable, of } from 'rxjs';
import { Meta } from '@tremaze/shared/models';

@Injectable({ providedIn: 'root' })
export class MockAuthV2Service implements AuthV2Service {
  private tenant: Tenant = new Tenant('1', 'Test Tenant');

  private tokenInfo: TokenInfo = new TokenInfo(
    'accessToken',
    'refreshToken',
    4108634812,
    100 * 365 * 24 * 60 * 60 * 1000,
    '',
    '',
  );

  private user: AuthenticatedUser = new AuthenticatedUser(
    '1',
    new Meta(),
    'Test User',
  );

  private loggedIn = true;

  get authenticated$(): Observable<boolean> {
    return of(this.loggedIn);
  }

  get authenticatedStateChanged$(): Observable<boolean> {
    return of(false);
  }

  get authenticated(): boolean {
    return this.loggedIn;
  }

  get notAuthenticated$(): Observable<boolean> {
    return of(!this.loggedIn);
  }

  get hasActiveTenant$(): Observable<boolean> {
    return of(true);
  }

  get activeTenant$(): Observable<Tenant> {
    return of(this.tenant);
  }

  get activeTenant(): Tenant {
    return this.tenant;
  }

  get authenticatedUserTenants$(): Observable<Tenant[]> {
    return of([this.tenant]);
  }

  get hasAuthenticatedUser$(): Observable<boolean> {
    return of(this.loggedIn);
  }

  get hasNoAuthenticatedUser$(): Observable<boolean> {
    return of(!this.loggedIn);
  }

  get authenticatedUser$(): Observable<AuthenticatedUser> {
    return of(this.user);
  }

  get tokenInfo$(): Observable<TokenInfo> {
    return of(this.tokenInfo);
  }

  loginWithUsernameAndPassword(
    username: string,
    password: string,
  ): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  init(): void {
    throw new Error('Method not implemented.');
  }

  logout(): void {
    throw new Error('Method not implemented.');
  }

  selectTenant(tenant: Tenant): void {
    throw new Error('Method not implemented.');
  }

  reloadAuthenticatedUser(): void {
    throw new Error('Method not implemented.');
  }

  reloadActiveTenant(): void {
    throw new Error('Method not implemented.');
  }

  refreshToken(): void {
    throw new Error('Method not implemented.');
  }

  getRefreshedToken(): Observable<TokenInfo> {
    throw new Error('Method not implemented.');
  }
}

export const provideMockAuthV2Service = () => ({
  provide: AuthV2Service,
  useClass: MockAuthV2Service,
});
