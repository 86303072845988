import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentEditorComponent } from '../document-editor.component';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';

@Component({
  selector: 'tremaze-document-editor-page',
  standalone: true,
  imports: [CommonModule, DocumentEditorComponent],
  templateUrl: './document-editor-page.component.html',
  styleUrl: './document-editor-page.component.scss',
})
export class DocumentEditorPageComponent {
  private readonly _route = inject(ActivatedRoute);
  readonly documentId$ = this._route.params.pipe(
    map((params) => params['id']),
    filterNotNullOrUndefined(),
    distinctUntilChanged(),
  );

  onRequestClose() {
    window.close();
  }
}
