import { Injectable } from '@angular/core';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { delay, Observable, of } from 'rxjs';
import {
  Approval,
  PartialBudget,
} from '@tremaze/shared/feature/approval/types';
import { EventFixtures, TestApprovalFixtures } from '@tremaze/fixtures';
import { TremazeEvent } from '@tremaze/shared/feature/event/types';
import { ApprovalDataSource } from '../approval-data-source';

@Injectable()
export class MockApprovalDataSource implements ApprovalDataSource {
  isVivendiIdUsed(vivendiId: string): Observable<boolean> {
    return of(false);
  }

  getPartialBudgetById(id: string): Observable<PartialBudget> {
    return of(TestApprovalFixtures.partialBudget1);
  }

  getApprovalsForUserAndService(
    userId: string,
    serviceId: string,
  ): Observable<Approval[]> {
    if (serviceId === '2') {
      return of([]);
    }
    if (serviceId === '3') {
      return of(
        TestApprovalFixtures.generateApprovalPage({
          filter: {
            page: 0,
            pageSize: 10,
          },
        }).content,
      );
    }
    return of([TestApprovalFixtures.approval1]);
  }

  getPaginatedApprovals(
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<Approval>> {
    return of(TestApprovalFixtures.generateApprovalPage(options));
  }

  getApprovalById(id: string): Observable<Approval> {
    return of(TestApprovalFixtures.generateApproval(parseInt(id))).pipe(
      delay(10),
    );
  }

  deleteApproval(id: string): Observable<boolean> {
    return of(true);
  }

  createApproval(approval: Approval): Observable<Approval> {
    return of(approval);
  }

  updateApproval(approval: Approval): Observable<Approval> {
    return of(approval);
  }

  getEventsForApproval(approvalId: string): Observable<TremazeEvent[]> {
    return of(
      EventFixtures.generateEventPage({
        filter: {
          page: 0,
          pageSize: 100,
        },
      }).content,
    ).pipe(delay(1));
  }

  getEventById(eventId: string): Observable<TremazeEvent> {
    return of(EventFixtures.generateEvent(1));
  }
}

export const provideMockApprovalDataSource = () => ({
  provide: ApprovalDataSource,
  useClass: MockApprovalDataSource,
});
