import { Injectable } from '@angular/core';
import { InstitutionContextService } from './shared-feature-institution-shared-singletons';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { Observable, of } from 'rxjs';
import { InstitutionFixtures } from '@tremaze/fixtures';

@Injectable()
export class MockInstitutionContextService
  implements InstitutionContextService
{
  get hasInstitution(): boolean {
    return true;
  }

  readonly currentInstitution$: Observable<Institution> = of(
    InstitutionFixtures.institution1,
  );
  currentInstitutionId$: Observable<string> = of(
    InstitutionFixtures.institution1.id,
  );

  setCurrentInstitution(inst: Institution): void {
    return;
  }

  loadInstitutionById(id: string, force?: boolean): Observable<Institution> {
    return of(InstitutionFixtures.institution1);
  }
}

export const provideMockInstitutionContextService = () => ({
  provide: InstitutionContextService,
  useClass: MockInstitutionContextService,
});
