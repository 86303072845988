<div *ngIf="task" class="progress-display-item">
  <span class="progress-display-item__name">{{ task.name }}</span>
  <div class="progress-display-item__progress-bar">
    <mat-progress-bar
      [color]="'primary'"
      [mode]="
        (task.is100PercentAndNotCompletedOrZeroPercent$ | async)
          ? 'indeterminate'
          : 'determinate'
      "
      [value]="task.progress$ | async"
    ></mat-progress-bar>
    <mat-icon [color]="'primary'">
      <span [class]="task.iconClass"></span>
    </mat-icon>
  </div>
  <span class="progress-display-item__speed">{{
    (task.hasError$ | async) ? 'FEHLER' : ((task.progress$ | async) ?? 0) + '%'
  }}</span>
</div>
