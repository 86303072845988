import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, Observable } from 'rxjs';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { NotificationService } from '@tremaze/shared/notification';
import { Client } from '@microsoft/microsoft-graph-client';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/lib/src/authentication/msal-browser/AuthCodeMSALBrowserAuthenticationProvider';
import { bindTo } from '@tremaze/shared/util/rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MSALAuthService {
  private readonly _initialized$ = new BehaviorSubject<boolean>(false);

  get loggedIn$(): Observable<AccountInfo | null> {
    return this._loggedIn$;
  }

  constructor(
    private authService: MsalService,
    private notificationService: NotificationService,
  ) {
    authService
      .initialize()
      .pipe(
        map(() => true),
        take(1),
        bindTo(this._initialized$),
      )
      .subscribe();
  }

  get isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  private _loggedIn$ = new BehaviorSubject<AccountInfo | null>(
    this.activeAccount,
  );

  private _graphClient: Client | null = null;

  getGraphClient(): Client {
    if (this._graphClient) {
      return this._graphClient;
    }

    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      this.authService.instance as PublicClientApplication,
      {
        account: this.activeAccount,
        scopes: ['user.read', 'calendars.readwrite'],
        interactionType: InteractionType.Popup,
      },
    );

    this._graphClient = Client.initWithMiddleware({ authProvider });
    return this._graphClient;
  }

  get activeAccount() {
    return this.authService.instance.getActiveAccount();
  }

  async login(): Promise<void> {
    await firstValueFrom(this._initialized$.pipe(filter((v) => v)));
    this.authService
      .loginPopup({
        scopes: ['user.read', 'calendars.readwrite'],
        redirectUri: window.location.origin,
      })
      .subscribe({
        next: (result) => this.onLoginSuccess(result),
        error: (err) => {
          console.error(err);
          this.notificationService.showDefaultErrorNotification();
        },
      });
  }

  logout(): void {
    this.authService.logoutPopup().subscribe({
      next: () => this.onLogoutSuccess(),
      error: () => this.notificationService.showDefaultErrorNotification(),
    });
  }

  private onLogoutSuccess() {
    this._loggedIn$.next(null);
    this.notificationService
      .showNotification('Die Abmeldung war erfolgreich! Lade neu...')
      .subscribe(() => window.location.reload());
  }

  private onLoginSuccess(authResult: AuthenticationResult) {
    this._loggedIn$.next(authResult.account);
    this.authService.instance.setActiveAccount(authResult.account);
    this.notificationService
      .showNotification('Die Anmeldung war erfolgreich! Lade neu...')
      .subscribe(() => window.location.reload());
  }
}
