import { inject, Injectable } from '@angular/core';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { firstValueFrom, Observable, switchMap, take } from 'rxjs';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { FilePreviewOverlayService } from '@tremaze/shared/feature/file-storage/ui/file-preview-overlay';
import { DocumentEditorService } from '@tremaze/shared/feature/document-editor';
import { PdfViewerService } from '@tremaze/shared/feature/pdf-viewer';
import { FolderViewUploadService } from './folder-view-upload.service';
import { blobToFile } from '@tremaze/shared/util-utilities';
import { Directory } from '@microsoft/microsoft-graph-types';
import { FileStoragePermissionsService } from '@tremaze/shared/feature/file-storage/feature/file-storage-permissions';
import { Placeholder } from '@tremaze/placeholder';

@Injectable()
export class FolderViewFileOpenerService {
  private readonly _fileStorageService = inject(FileStorageService);
  private readonly _previewService = inject(FilePreviewOverlayService);
  private readonly _documentEditorService = inject(DocumentEditorService);
  private readonly _pdfViewerService = inject(PdfViewerService);
  private readonly _uploadService = inject(FolderViewUploadService);
  private readonly _permissionService = inject(FileStoragePermissionsService);

  placeholders: Placeholder[] = [];

  async openFile(file: FileStorage, currentDirectory$: Observable<DirStorage>) {
    const url = await firstValueFrom(
      this._fileStorageService.getFileDownloadURL(file),
    );
    const canWrite = await firstValueFrom(
      currentDirectory$.pipe(
        take(1),
        switchMap((dir) =>
          this._permissionService.hasWritePermissionForStorage(file, dir),
        ),
      ),
    );

    if (file.type === 'PDF') {
      this._pdfViewerService
        .openPDF({
          pdfUrl: url,
          pdfName: file.fileViewname,
          placeholders: this.placeholders,
          canWrite,
        })
        .componentInstance.save.subscribe((blob) => {
          if (canWrite) {
            this._uploadBlob(blob, file, currentDirectory$);
          }
        });
      return;
    }

    if (file.type === 'DOCUMENT') {
      this._documentEditorService.openPage({
        fileStorage: file,
      });

      return;
    }

    this._previewService.open({
      data: {
        name: file.fileViewname,
        type: file.type,
        url,
      },
    });
  }

  private async _uploadBlob(
    blob: Blob,
    fileStorage: FileStorage,
    currentDirectory$: Observable<Directory>,
    cfg?: { silentOverwrite: boolean },
  ) {
    const file = blobToFile(
      blob,
      fileStorage.fileViewname,
      fileStorage.fileType,
    );
    await firstValueFrom(
      this._uploadService.overwriteFile(fileStorage.id, file, {
        silent: cfg?.silentOverwrite ?? false,
      }),
    );
  }
}
