import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[tremazeCircleAvatarBadge]',
})
export class CircleAvatarBadgeDirective {
  @HostBinding('class') get hostClass(): string {
    return 'circle-avatar__Badge';
  }
}
