import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable, of, switchMap } from 'rxjs';
import {
  CustomForm,
  CustomFormFeature,
} from '@tremaze/shared/feature/custom-forms/types';
import { CustomFormDataSource } from '@tremaze/shared/feature/custom-forms/data-access';
import { CustomFormEditDialogComponent } from './custom-form-edit-dialog/custom-form-edit-dialog.component';
import { ConfirmationService } from '@tremaze/shared/feature/confirmation';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';

@Injectable()
export class CustomFormEditService {
  constructor(
    private readonly _dialog: MatDialog,
    private readonly _dataSource: CustomFormDataSource,
    private readonly _confirmationService: ConfirmationService,
    private readonly _fileStorageService: FileStorageService,
  ) {}

  create(config?: {
    feature?: CustomFormFeature;
    institutionId?: string;
  }): Observable<CustomForm | undefined> {
    return this._dialog
      .open(CustomFormEditDialogComponent, {
        panelClass: 'relative-dialog',
        disableClose: true,
        data: config,
      })
      .afterClosed();
  }

  edit(formId: string): Observable<CustomForm | undefined> {
    return this._dialog
      .open(CustomFormEditDialogComponent, {
        data: { formId },
        panelClass: 'relative-dialog',
        disableClose: true,
      })
      .afterClosed();
  }

  deleteById(formId: string): Observable<boolean> {
    return this._dataSource.deleteById(formId);
  }

  removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean> {
    return this._confirmationService.askUserForConfirmation().pipe(
      map((c) => c?.confirmed),
      switchMap((confirmed) =>
        !confirmed
          ? of(false)
          : this._dataSource.removeFormFromInstitution(formId, institutionId),
      ),
    );
  }

  setCSVExportTemplate(
    formId: string,
    csvExportTemplate: string,
  ): Observable<boolean> {
    return this._dataSource.setCSVExportTemplate(formId, csvExportTemplate);
  }

  getCSVExportTemplate(formId: string): Observable<string> {
    return this._dataSource.getCSVExportTemplate(formId);
  }

  uploadPdfTemplate(formId: string, file: File): Observable<FileStorage> {
    return this._fileStorageService
      .uploadFilesTo([file], '/files/upload/reportTemplate')
      .pipe(map((r) => r[0]));
  }

  setPdfTemplate(
    formId: string,
    fileStorageId: string | null,
  ): Observable<boolean> {
    return this._dataSource.setPdfExportTemplateFile(formId, fileStorageId);
  }

  getPdfTemplate(formId: string): Observable<FileStorage | null> {
    return this._dataSource.getPdfExportTemplateFile(formId);
  }
}
