import { PageGenerator } from '../helpers';
import { TremazeLocation } from '@tremaze/location-types';
import { Address } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';

class LocationFixturesPageGenerator extends PageGenerator<TremazeLocation> {
  override generateByIndex(index: number): TremazeLocation {
    return LocationFixtures.generateLocation(index);
  }
}

export abstract class LocationFixtures {
  static generateLocation(id: number) {
    return new TremazeLocation(
      id.toString(),
      `Location ${id}`,
      new Address(
        undefined,
        `City ${id}`,
        `${10000 + id}`,
        `Street ${id}`,
        `Addition ${id}`,
      ),
    );
  }

  static generateLocationPage(options: DataSourceMethodsPaginatedOptions) {
    return new LocationFixturesPageGenerator().generatePage(options);
  }
}
