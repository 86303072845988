import { Component, Input } from '@angular/core';
import { BackgroundTask } from '@tremaze/shared/ui/progress-display/types';

@Component({
  selector: 'tremaze-progress-display-item',
  templateUrl: './progress-display-item.component.html',
  styleUrls: ['./progress-display-item.component.scss'],
})
export class ProgressDisplayItemComponent {
  private _task: BackgroundTask;

  get task(): BackgroundTask {
    return this._task;
  }

  @Input() set task(val: BackgroundTask) {
    this._task = val;
  }
}
