import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, of, throwError, zip } from 'rxjs';
import { catchError, first, map, switchMap, take } from 'rxjs/operators';
import {
  AuthV2ModuleConfig,
  AuthV2ModuleConfigInjectionToken,
  TokenInfo,
} from '@tremaze/shared/core/auth-v2/types';
import { AuthV2Service } from './auth-service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Optional()
    @Inject(AuthV2ModuleConfigInjectionToken)
    private authV2ModuleConfig?: AuthV2ModuleConfig,
    @Optional() private authService?: AuthV2Service,
  ) {}

  static addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: { Authorization: 'Bearer ' + token },
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const tokenInfo$ =
      !request.params.has('skipAuth') && this.authService
        ? this.authService.tokenInfo$.pipe(first())
        : of(null);
    return zip(tokenInfo$, this.getTenantID$(request)).pipe(
      switchMap(([tokenInfo, tenantId]) => {
        let headersObj = {};
        if (
          tokenInfo instanceof TokenInfo &&
          !request.url.includes('public/')
        ) {
          headersObj = { Authorization: 'Bearer ' + tokenInfo.access_token };
        }
        if (tenantId) {
          headersObj = { ...headersObj, 'X-TenantID': tenantId };
        }
        request = request.clone({ setHeaders: headersObj });
        return next
          .handle(request)
          .pipe(catchError((e) => this.handleError(e, request, next)));
      }),
    );
  }

  handleError(
    err: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 400:
          return this.handle400(err);
        case 401:
          return this.handle401Error(err, req, next);
        case 403:
          return this.handle403();
        case 502:
          break;
        default:
          return throwError(err);
      }
    } else {
      return throwError(err);
    }
  }

  handle400(err: HttpErrorResponse) {
    if (
      err &&
      err.status === 400 &&
      err.error &&
      err.error.error === 'invalid_grant'
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logout(err);
    }
    return throwError(err);
  }

  handle401Error(
    err: HttpErrorResponse,
    req: HttpRequest<any>,
    next: HttpHandler,
  ) {
    if (typeof req.body === 'string' && req.body.includes('refresh_token')) {
      return this.logout(
        'Deine Sitzung ist abgelaufen. Bitte melde dich erneut an',
      );
    } else if (req.url.includes('logout')) {
      return of(true);
    } else if (req.url.includes('token')) {
      return throwError(err);
    }

    // Reset here so that the following requests wait until the token
    // comes back from the refreshToken call.
    return this.authService.getRefreshedToken().pipe(
      switchMap((newToken) => {
        if (newToken instanceof TokenInfo) {
          // Reload auth user since token got deleted somehow so it's worth checking if i.e. the permissions have changed
          // this._authService.reloadAuthenticatedUserData();
          return next.handle(
            AuthInterceptor.addToken(req, newToken.access_token),
          );
        }

        // If we don't get a new token, we are in trouble so logout.
        return this.logout("Couldn't refresh token");
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if (error.status === 401) {
          this.logout("Couldn't refresh token");
        }
        return throwError(error);
      }),
    );
  }

  handle403() {
    return throwError('permission-denied');
  }

  logout(err: any) {
    this.authService.logout();
    return of(null);
  }

  private getTenantID$(request: HttpRequest<unknown>) {
    // As of now multi tenancy is only supported if oid is used
    if (
      !request.params.has('skipTenantId') &&
      this.authService &&
      this.authV2ModuleConfig?.oidcConfig
    ) {
      return this.authService.activeTenant$.pipe(
        take(1),
        map(({ id }) => id),
      );
    }
    return of(null);
  }
}
