import { Type } from '@angular/core';

export type ComponentOrTemplateRef<C> = Type<C>;

export interface PopupAction {
  label: string;
  action: () => void;
}

export interface PopupOptions {
  title: string;
  actions?: PopupAction[];
  data?: any;
  maxWidth?: string;
  mode?: 'AUTO' | 'DIALOG' | 'BOTTOM_SHEET';
  disableClose?: boolean;
}
