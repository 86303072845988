import { Linearicon } from '@tremaze/shared/ui';
import { BulkActionService } from '@tremaze/bulk-action';

export type DataTableBulkActionConfig<T> = {
  warn?: boolean;
  icon: Linearicon;
  name: string;
} & Pick<
  Parameters<BulkActionService['openDialog']>[0],
  'fetchItem' | 'execute' | 'isActionPermitted'
>;
