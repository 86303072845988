import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Division } from '@tremaze/shared/feature/division/types';
import {
  InstitutionCRUDDataSource,
  InstitutionREADDataSource,
} from './shared-feature-institution-data-access';
import { Pagination } from '@tremaze/shared/models';
import {
  DataSourceMethodsBaseOptions,
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  Deserializer,
} from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { Observable, of } from 'rxjs';
import { Institution } from '@tremaze/shared/feature/institution/types';
import { InstitutionFixtures } from '@tremaze/fixtures';

@Injectable()
export class MockInstitutionDataSource extends InstitutionCRUDDataSource {
  getAll(): Observable<Institution[]> {
    throw new Error('Method not implemented.');
  }

  create(
    i: Institution & { division?: Division },
    options?: DataSourceMethodsCreateOptions<Institution>,
  ): Observable<Institution> {
    return of(i);
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<any>,
  ): Observable<Institution> {
    return of(InstitutionFixtures.generateInstitution(1));
  }

  edit(
    i: Institution,
    options?: DataSourceMethodsEditOptions<Institution>,
  ): Observable<Institution> {
    return of(i);
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsBaseOptions,
  ): Observable<boolean> {
    return of(true);
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<Institution>> {
    return of(InstitutionFixtures.generateInstitutionPage(options));
  }

  protected deserializer: Deserializer<Institution>;
  protected js: JsonSerializer;
  protected http: HttpClient;
  protected controller: string;
}

export const provideMockInstitutionREADDataSource = () => ({
  provide: InstitutionREADDataSource,
  useClass: MockInstitutionDataSource,
});

export const provideMockInstitutionCRUDDataSource = () => ({
  provide: InstitutionCRUDDataSource,
  useClass: MockInstitutionDataSource,
});
