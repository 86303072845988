import {
  DataSourceMethodsGetFreshOptions,
  DefaultREADDataSourceWithPaginationImpl,
  qry,
} from '@tremaze/shared/util-http';
import { JsonSerializer } from '@tremaze/shared/util-json-serializer';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomFormSubmission } from '@tremaze/shared/feature/custom-forms/types';
import { map, Observable } from 'rxjs';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

/**
 * This is a custom data source that is used to read the submissions for a form.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomFormSubmissionDataSourceDefaultImpl extends DefaultREADDataSourceWithPaginationImpl<CustomFormSubmission> {
  protected controller = 'forms/submissions';

  filterFields = [
    'FORM_NAME',
    'FILLED_OUT_FOR_USERNAME',
    'FILLED_OUT_FOR_FIRST_NAME',
    'FILLED_OUT_FOR_LAST_NAME',
    'FILLED_OUT_BY_USERNAME',
    'FILLED_OUT_BY_FIRST_NAME',
    'FILLED_OUT_BY_LAST_NAME',
  ];

  protected deserializer = CustomFormSubmission.deserialize;

  constructor(
    protected http: HttpClient,
    protected js: JsonSerializer,
    private appConfig: AppConfigService,
  ) {
    super();
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<CustomFormSubmission> & {
      useNewestFormVersion?: boolean;
    },
  ): Observable<CustomFormSubmission> {
    const useNewestFormVersion = options?.useNewestFormVersion ?? false;
    const q: qry = {
      ...options?.q,
      newestFormVersion: useNewestFormVersion.toString(),
    };
    return super.getFreshById(id, { ...options, q });
  }

  getTotalAmountUncompletedSubmissions(config?: {
    userId?: string;
  }): Observable<number> {
    return this.http.get<number>(`${this.controller}/uncompleted/count`, {
      params: config,
    });
  }

  completeSubmission(submissionId: string): Observable<void> {
    return this.http.put<void>(
      `${this.controller}/${submissionId}/complete`,
      {},
      { params: { completed: 'true' } },
    );
  }

  getReportForSubmission(submissionId: string): Observable<FileStorage | null> {
    return this.http
      .get<FileStorage | null>(`${this.controller}/${submissionId}/report`)
      .pipe(map((r) => (r ? FileStorage.deserialize(r) : null)));
  }

  deleteReportForSubmission(submissionId: string): Observable<void> {
    return this.http.delete<void>(`${this.controller}/${submissionId}/report`);
  }
}
