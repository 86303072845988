import { Injectable } from '@angular/core';
import { FolderViewDataSource } from '../folder-view-data-source';
import { delay, Observable, of } from 'rxjs';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { DirStorageFixtures, FileStorageFixtures } from '@tremaze/fixtures';

@Injectable()
export class FolderViewDataSourceMock implements FolderViewDataSource {
  uploadFilesToPath(p: {
    files: File[];
    absolutePath: string;
    onProgress?: ((progress: number) => void) | undefined;
  }): Observable<FileStorage[]> {
    return of(FileStorageFixtures.all);
  }

  updateDirectoryName(p: {
    directoryId: string;
    newName: string;
  }): Observable<DirStorage> {
    return of(DirStorageFixtures.dirStorage1);
  }

  getDirectoryByAbsolutePath(path: string): Observable<DirStorage> {
    return of(DirStorageFixtures.generateDirStorageByAbsolutePath(path));
  }

  getFilesForDirectory(directoryId: string): Observable<FileStorage[]> {
    return of(FileStorageFixtures.all).pipe(delay(300));
  }

  getSubDirectoriesByAbsolutePath(
    absolutPath: string,
  ): Observable<DirStorage[]> {
    return of(DirStorageFixtures.dirStorage1.subDirs);
  }

  searchDirsAndFiles(p: {
    searchValue: string;
    searchFromDirId?: string | undefined;
  }): Observable<(DirStorage | FileStorage)[]> {
    return of([...DirStorageFixtures.all, ...FileStorageFixtures.all]);
  }

  moveFilesAndDirectoriesToPath(p: {
    fileIds: string[];
    folderIds: string[];
    absolutePath: string;
  }): Observable<void> {
    return of();
  }

  copyFileToFolder(p: {
    fileId: string;
    folderId: string;
    fileName: string;
  }): Observable<FileStorage> {
    return of(FileStorageFixtures.fileStorage1);
  }

  uploadFilesToDirectory(p: {
    files: File[];
    destinationDirId: string;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage[]> {
    return of([]);
  }

  deletesFiles(...ids: string[]): Observable<void> {
    return of();
  }

  deleteDirectories(...ids: string[]): Observable<void> {
    return of();
  }

  createDirectory(p: {
    directoryName: string;
    parentId: string;
  }): Observable<DirStorage> {
    return of(DirStorageFixtures.dirStorage1);
  }
}

export const provideMockFolderViewDataSource = () => ({
  provide: FolderViewDataSource,
  useClass: FolderViewDataSourceMock,
});
