<mat-drawer-container>
  <mat-drawer-content>
    <ejs-pdfviewer
      (toolbarClick)='onToolbarClick($event)'
      [downloadFileName]='documentName'
      [enableDownload]='true'
      [enablePrint]='true'
      [printScaleRatio]='5'
      [resourceUrl]='resource'
      [serviceUrl]='serviceUrl'
      [toolbarSettings]='toolbarSettings'
      id='pdfviewer'
      locale='de-DE'
      style='height:100%;display:block'
    >
    </ejs-pdfviewer>
  </mat-drawer-content>

  <mat-drawer [opened]='showFillableItems' mode='side' position='end'>
    <tremaze-placeholder-table
      (fill)='fillFields()'
      [placeholders]='placeholders'
    ></tremaze-placeholder-table>
  </mat-drawer>
</mat-drawer-container>
