/**
 * Approval service model
 *
 * @description
 * Represents an approval service entity
 */
export type ApprovalService = {
  readonly id: string;
  /** The approval service short name */
  readonly shortName: string;
  /** The approval service description */
  readonly description?: string;
  /** The approval service value in euros */
  readonly value?: number;
  /** The approval service external id */
  readonly externalId?: string;
  /**
   * For grouped approval services, this property contains the sub-approval services
   */
  readonly subApprovalServices?: ApprovalService[];

  readonly subService?: boolean;
};
