import { CustomFormExportSchema } from '@tremaze/shared/feature/custom-forms/types';
import { CustomFormSchemasFixtures } from './export-schema-placeholders';

export abstract class CustomFormExportSchemaFixtures {
  static readonly generateExportSchema = (
    formId: string,
  ): CustomFormExportSchema => {
    return {
      delimiter: ',',
      headerRowIndex: 0,
      columns: [
        {
          columnName: 'Column 1',
          formFieldIdentifier:
            CustomFormSchemasFixtures.all.formFieldPlaceholders.latest
              .placeholders[0].name,
          sort: 0,
        },
        {
          columnName: 'Column 2',
          formFieldIdentifier:
            CustomFormSchemasFixtures.all.formFieldPlaceholders.other[0]
              .placeholders[0].name,
          sort: 1,
        },
        {
          columnName: 'Column 3',
          otherDataIdentifier:
            CustomFormSchemasFixtures.all.otherPlaceholders[0].name,
          sort: 2,
        },
      ],
    };
  };
}
