import { Placeholder } from '@tremaze/placeholder';
import { User } from './shared-feature-user-types';

export abstract class UserPlaceholderHelpers {
  static mapUserToPlaceholders(
    u?: User,
    prefixConfig?: {
      labelPrefix?: string;
      namePrefix?: string;
    },
  ): Placeholder[] {
    const placeholders = [
      { label: 'Vorname', name: '$vorname', value: u?.firstName },
      { label: 'Nachname', name: '$nachname', value: u?.lastName },
      { label: 'Voller Name', name: '$voller-name', value: u?.fullName },
      { label: 'Benutzername', name: '$benutzername', value: u?.username },
      {
        label: 'Einrichtung',
        name: '$einrichtung',
        value: u?.institutions?.[0]?.name,
      },
      {
        label: 'Name, Vorname',
        name: '$name-vorname',
        value: u?.familyNameFirstName,
      },
      { label: 'Geschlecht', name: '$geschlecht', value: u?.gender?.name },
      {
        label: 'Geburtsdatum',
        name: '$geburtsdatum',
        value: u?.birth?.format('DD.MM.YYYY'),
      },
      {
        label: 'Personalnummer',
        name: '$personalnummer',
        value: u?.personnelNumber,
      },
      { label: 'E-Mail', name: '$email', value: u?.contact.email },
      { label: 'Telefon', name: '$telefon', value: u?.contact.phone },
      { label: 'Mobil', name: '$mobil', value: u?.contact.mobile },
      {
        label: 'Straße',
        name: '$strasse',
        value: u?.address?.street ?? '',
      },
      { label: 'PLZ', name: '$plz', value: u?.address?.zip ?? '' },
      { label: 'Ort', name: '$ort', value: u?.address?.city ?? '' },
      { label: 'Land', name: '$land', value: u?.address?.country?.name ?? '' },
      {
        label: 'Weitere Angaben',
        name: '$weitere_angaben',
        value: u?.other,
      },
      {
        label: 'Externe Referenz',
        name: '$externe_referenz',
        value: u?.externalFileNumber,
      },
      {
        label: 'Internes Aktenzeichen',
        name: '$internes_aktenzeichen',
        value: u?.internalFileNumber,
      },
    ];

    if (prefixConfig) {
      placeholders.forEach((p) => {
        p.label = prefixConfig.labelPrefix
          ? `${prefixConfig.labelPrefix}${p.label}`
          : p.label;
        p.name = prefixConfig.namePrefix
          ? `${prefixConfig.namePrefix}${p.name}`
          : p.name;
      });
    }

    return placeholders;
  }
}
