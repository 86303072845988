import { Injectable } from '@angular/core';
import { LocationDataSource } from '../data-source';
import { TremazeLocation } from '@tremaze/location-types';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable, of } from 'rxjs';
import { LocationFixtures } from '@tremaze/fixtures';

@Injectable()
export class MockLocationDataSource implements LocationDataSource {
  getLocationsPageForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<TremazeLocation>> {
    return of(LocationFixtures.generateLocationPage(options));
  }

  deleteLocationById(id: string): Observable<void> {
    return of();
  }

  createLocation(
    instId: string,
    location: Omit<TremazeLocation, 'id'>,
  ): Observable<TremazeLocation> {
    return of(new TremazeLocation('1', location.name, location.address));
  }

  updateLocation(location: TremazeLocation): Observable<TremazeLocation> {
    return of(location);
  }

  getLocationById(id: string): Observable<TremazeLocation> {
    return of(LocationFixtures.generateLocation(parseInt(id, 10)));
  }
}

export const provideMockLocationDataSource = () => ({
  provide: LocationDataSource,
  useClass: MockLocationDataSource,
});
