import { Injectable } from '@angular/core';
import { DataTableActionsService } from './data-table-actions.service';

@Injectable()
export class MockDataTableActionsService implements DataTableActionsService {
  edit(id: string, onSuccess?: (r: unknown) => void) {
    console.log('edit', id);
    return;
  }

  create(onSuccess?: (r: unknown) => void) {
    console.log('create');
    return;
  }

  delete(id: string, onSuccess?: () => void) {
    console.log('delete', id);
    return;
  }
}

export const provideMockDataTableActionsService = () => ({
  provide: DataTableActionsService,
  useClass: MockDataTableActionsService,
});
