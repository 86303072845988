<mat-card>
  <mat-card-header>
    <h3 mat-card-title>Platzhalter</h3>
    <mat-card-subtitle
    >Füge die Platzhalter als Namen von Formularfeldern ein, um sie
      ersetzen zu können
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table class='default-table'>
      <thead>
      <tr>
        <th>Name</th>
        <th>Platzhalter</th>
        @if (showValues) {
          <th>Wert</th>
        }
      </tr>
      </thead>
      <tbody>
        @for (item of placeholders; track item.name) {
          <tr>
            <th>{{ item.label }}</th>
            <td>
              {{ item.name }}
            </td>
            @if (showValues) {
              <td>{{ item.value }}</td>
            }
            <td>
              <button mat-icon-button matTooltip='Kopieren' (click)='onClickCopy(item)'>
                <tremaze-icon icon='lnr-copy'></tremaze-icon>
              </button>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </mat-card-content>
  @if (showValues) {
    <mat-card-actions align='end'>
      <button (click)='fill.emit()' mat-button>
        Platzhalter ausfüllen
      </button>
    </mat-card-actions>
  }
</mat-card>
