import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTenant from './+state/tenant.reducer';
import { TenantEffects } from './+state/tenant.effects';
import { TenantFacade } from './+state/tenant.facade';
import { RemotePublicTenantDataSource } from '@tremaze/shared/feature/tenant/data-access';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromTenant.TENANT_FEATURE_KEY, fromTenant.reducer),
    EffectsModule.forFeature([TenantEffects]),
  ],
  providers: [TenantFacade, RemotePublicTenantDataSource],
})
export class SharedFeatureTenantModule {}
