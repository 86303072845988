import { booleanAttribute, Component, Input } from "@angular/core";
import { TremazeDate } from "@tremaze/shared/util-date";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { IconComponent } from "@tremaze/shared/ui/icon";

@Component({
  selector: 'tremaze-autosave-display',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule, IconComponent],
  template: `
    @if (isSaving) {
      <mat-spinner diameter="17"></mat-spinner>
    } @else {
      <tremaze-icon icon="lnr-check"></tremaze-icon>
    }
    <span>Zuletzt gespeichert: {{ lastSave | date: 'dd.MM.yyyy HH:mm:ss' }}</span>
  `,
  styles: `
    :host {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
    }

    tremaze-icon {
      color: green;
      font-size: inherit;
      width: 17px;
    }
  `,
})
export class AutosaveDisplayComponent {
  @Input({transform: booleanAttribute}) isSaving?: boolean;
  @Input() lastSave?: TremazeDate;
}
