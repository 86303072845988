import { Injectable } from '@angular/core';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable, of } from 'rxjs';
import { CostBearer } from '@tremaze/shared/feature/approval/types';
import { CostBearerFixtures } from '@tremaze/fixtures';
import { CostBearerDataSource } from '../cost-bearer-data-source';

@Injectable({
  providedIn: 'root',
})
export class MockCostBearerDataSource implements CostBearerDataSource {
  createCostBearer(
    costBearer: Omit<CostBearer, 'id'>,
    institutionId: string,
  ): Observable<CostBearer> {
    return of(
      CostBearer.deserialize({
        ...costBearer,
        id: '1',
      }),
    );
  }

  updateCostBearer(costBearer: CostBearer): Observable<CostBearer> {
    return of(costBearer);
  }

  deleteCostBearer(id: string): Observable<boolean> {
    return of(true);
  }

  getCostBearerById(id: string): Observable<CostBearer> {
    return of(CostBearerFixtures.generateCostBearer(1));
  }

  getPaginatedCostBearers(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<CostBearer>> {
    return of(CostBearerFixtures.generateCostBearerPage(options));
  }

  getCostBearerByName(instId: string, name: string): Observable<CostBearer> {
    return of(CostBearerFixtures.generateCostBearer(1));
  }
}

export const provideMockCostBearerDataSource = () => ({
  provide: CostBearerDataSource,
  useClass: MockCostBearerDataSource,
});
