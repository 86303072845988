import { Observable } from 'rxjs';
import {
  FileStorage,
  FileStorageEntityMeta,
  FileStorageEntityType,
} from '@tremaze/shared/feature/file-storage/types';

export abstract class FileStorageDataSource {
  abstract downloadFile(p: {
    fileId: string;
    fleType?: string;
    onProgress?: (progress: number) => void;
  }): Observable<Blob>;

  abstract deletesFiles(...ids: string[]): Observable<void>;

  abstract uploadAvatar(p: {
    file: File;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage>;

  abstract overwriteFile(p: {
    fileId: string;
    file: File;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage>;

  abstract uploadEntityFiles(p: {
    files: File[];
    entity: FileStorageEntityType;
    entityMeta?: FileStorageEntityMeta;
    onProgress?: (progress: number) => void;
  }): Observable<FileStorage[]>;

  abstract uploadFilesWithEndpoint(p: {
    files: File[];
    endpoint: string;
    onProgress?: (progress: number) => void;
    method?: 'POST' | 'PUT';
    filesParamName?: string;
  }): Observable<FileStorage[]>;

  abstract updateFileName(p: {
    fileId: string;
    newName: string;
  }): Observable<FileStorage>;

  abstract getFileById(fileId: string): Observable<FileStorage>;
}
