import { Injectable } from '@angular/core';
import { CustomFormDataSource } from './custom-form-data-source';
import { Pagination } from '@tremaze/shared/models';
import {
  DataSourceMethodsBaseOptions,
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
} from '@tremaze/shared/util-http';
import { Observable, of } from 'rxjs';
import {
  CustomForm,
  CustomFormExportSchema,
  CustomFormExportSchemaPlaceholders,
  CustomFormSubmission,
} from '@tremaze/shared/feature/custom-forms/types';
import {
  CustomFormExportSchemaFixtures,
  CustomFormFixtures,
  CustomFormSchemasFixtures,
} from '@tremaze/fixtures';
import { TremazeDate } from '@tremaze/shared/util-date';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

@Injectable()
export class MockCustomFormDataDataSource implements CustomFormDataSource {
  setExportSchemaForForm(
    formId: string,
    schema: CustomFormExportSchema | null,
  ): Observable<CustomFormExportSchema | null> {
    return new Observable((subscriber) => {
      subscriber.next();
      subscriber.complete();
    });
  }

  exportFormSubmissions(
    formId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    institutionIds?: string[],
  ): Observable<Blob> {
    return of(new Blob());
  }

  getExportSchemaForForm(formId: string): Observable<CustomFormExportSchema> {
    return of(CustomFormExportSchemaFixtures.generateExportSchema(formId));
  }

  getAvailablePlaceholdersForForm(
    formId: string,
  ): Observable<CustomFormExportSchemaPlaceholders> {
    return of(CustomFormSchemasFixtures.all);
  }

  deleteById(
    id: string,
    options?: DataSourceMethodsBaseOptions | undefined,
  ): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  edit(
    i: CustomForm,
    options?: DataSourceMethodsEditOptions<CustomForm> | undefined,
  ): Observable<CustomForm> {
    throw new Error('Method not implemented.');
  }

  getManyFreshByIds(
    ids: string[],
    options?: DataSourceMethodsGetFreshOptions<CustomForm> | undefined,
  ): Observable<CustomForm[]> {
    throw new Error('Method not implemented.');
  }

  getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<any> | undefined,
  ): Observable<CustomForm> {
    return of(CustomFormFixtures.generateCustomForm(id));
  }

  getPaginated(
    options?: DataSourceMethodsPaginatedOptions<any> | undefined,
  ): Observable<Pagination<CustomForm>> {
    throw new Error('Method not implemented.');
  }

  create(
    i: CustomForm,
    options?: DataSourceMethodsCreateOptions<CustomForm> | undefined,
  ): Observable<CustomForm> {
    throw new Error('Method not implemented.');
  }

  setPublished(formId: string, published: boolean): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  getFilledOutFormForUser(
    formId: string,
    userId: string,
  ): Observable<CustomFormSubmission | null> {
    throw new Error('Method not implemented.');
  }

  removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  setPdfExportTemplateFile(
    formId: string,
    fileId: string,
  ): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  getPdfExportTemplateFile(formId: string): Observable<FileStorage | null> {
    throw new Error('Method not implemented.');
  }

  setCSVExportTemplate(formId: string, template: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  getCSVExportTemplate(formId: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  getFormsByNames(names: string[]): Observable<CustomForm[]> {
    throw new Error('Method not implemented.');
  }
}

export const provideMockCustomFormDataDataSource = () => ({
  provide: CustomFormDataSource,
  useClass: MockCustomFormDataDataSource,
});
