import { CustomFormFieldType } from '../form-field-types';
import { Meta } from '@tremaze/shared/models';

export interface CustomFormFieldValue<T> {
  readonly id: string;
  readonly meta: Meta;
  readonly fieldType: CustomFormFieldType;
  readonly value: T;

  toDisplayString(): string;
}
