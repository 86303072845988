@if (service.hasTasks$ | async) {
  <div [class.expanded]="expanded" class="progress-display-wrapper">
    <mat-card
      appearance="raised"
      [class.hidden]="hide"
      cdkDrag
      cdkDragLockAxis="x"
      class="progress-display"
    >
      <mat-toolbar
        cdkDragHandle
        class="progress-display__head mat-elevation-z2"
        color="primary"
      >
        <span
          >({{ service.completedTaskCount$ | async }}/{{
            service.taskCount$ | async
          }})</span
        >
        <mat-progress-bar
          [value]="service.totalProgress$ | async"
          color="accent"
        ></mat-progress-bar>
        <button (click)="onClickExpandButton()" color="accent" mat-icon-button>
          <span class="lnr lnr-chevron-down"></span>
        </button>
      </mat-toolbar>
      <div class="progress-display__body">
        @for (task of service.tasks$ | async; track task.id) {
          <tremaze-progress-display-item
            [@simpleSizeAnimation]="'in'"
            [task]="task"
          ></tremaze-progress-display-item>
        }
      </div>
    </mat-card>
  </div>
}
