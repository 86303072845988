import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { NotificationService } from '@tremaze/shared/notification';
import { MatButton } from '@angular/material/button';
import { NgControl } from '@angular/forms';
import { PriceLevel } from '@tremaze/shared/feature/event/types';
import { PriceLevelSettingService } from './price-level-setting.service';

@Directive({
  selector: 'button[tremazePriceLevelSettingButton]',
})
export class PriceLevelSettingButtonDirective
  implements ControlValueAccessor<PriceLevel[]>
{
  constructor(
    private notificationService: NotificationService,
    private elRef: ElementRef<HTMLElement>,
    private selectionService: PriceLevelSettingService,
    @Optional() private componentRef: MatButton,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _value: PriceLevel[] | null;

  @Input()
  get value(): PriceLevel[] | null {
    return this._value;
  }

  set value(value: PriceLevel[] | null) {
    const amount = (value || []).length;
    const amountString = amount === 0 ? 'Keine' : amount.toString();
    this.elRef.nativeElement.innerHTML = `${amountString} Preisklasse${
      amount === 1 ? '' : 'n'
    }`;
    this._value = value;
  }

  @HostListener('click')
  async onClick() {
    const res = await this.selectionService
      .setPriceLevels(this.value)
      .toPromise();
    if (Array.isArray(res)) {
      this.writeValue(res);
    }
  }

  writeValue(value: PriceLevel[]): void {
    this.value = value;
    this.onChangeCallback(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  private onTouchedCallback = () => null;

  private onChangeCallback = (v) => null;
}
