import { inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import {
  catchError,
  debounceTime,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  combineLatest,
  distinctUntilChanged,
  iif,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { DefaultCRUDDataSource, qry } from '@tremaze/shared/util-http';
import { Sort, SortDirection } from '@angular/material/sort';
import { IdObject } from '@tremaze/shared/util/id-object';
import { NotificationService } from '@tremaze/shared/notification';
import { DataTableDataSource } from './data-source';
import { DataTableService } from './data-table.service';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';
import {
  PersonalConfig,
  PersonalConfigFilterSetting,
  PersonalConfigService,
} from '@tremaze/personal-config';
import { DataTableDisableFilterPersistenceDirective } from './data-table-disable-filter-persistence.directive';
import { DataTablePersonalConfigSettingsKeyDirective } from './data-table-personal-config-settings-key.directive';

export interface DataTableState<T> {
  content: T[];
  currentPageIndex: number;
  currentPageSize: number;
  totalElementCount: number;
  loading: boolean;
  sort?: string;
  sortDirection?: SortDirection;
  filterFields?: string[];
  filterValue?: string;
  institutions?: PersonalConfigFilterSetting['filterInstitutions'];
  departments?: PersonalConfigFilterSetting['filterDepartments'];
  users?: PersonalConfigFilterSetting['filterUsers'];
  queryParameters?: qry;
  idEndpoint?: string;
}

interface PaginatorChangeEvent {
  pageSize: number;
  page: number;
}

interface SortChangeEvent {
  sort: string;
  sortDirection: SortDirection;
}

interface FilterChangeEvent {
  filterFields: string[];
  filterValue: string;
}

@Injectable()
export class DataTableStore<T extends IdObject>
  extends ComponentStore<DataTableState<T>>
  implements OnDestroy
{
  readonly _personalConfigService = inject(PersonalConfigService);

  private readonly _personalConfigSettingsKeyDirective = inject(
    DataTablePersonalConfigSettingsKeyDirective,
    { optional: true },
  );

  private readonly _disableFilterPersistence =
    !!inject(DataTableDisableFilterPersistenceDirective, { optional: true }) ||
    !this._personalConfigSettingsKeyDirective;

  private get _personalConfigSettingsKey():
    | keyof PersonalConfig['filterSettings']
    | undefined {
    return this._personalConfigSettingsKeyDirective?.key;
  }

  private readonly _personalConfigSettingsKey$?:
    | Observable<keyof PersonalConfig['filterSettings']>
    | undefined = this._personalConfigSettingsKeyDirective?.key$;

  readonly initialSort$: Observable<Sort | null> = iif(
    () => this._disableFilterPersistence,
    of(null),
    this._personalConfigService.personalConfig$.pipe(
      filterNotNullOrUndefined(),
      map(
        (config) =>
          config.filterSettings[this._personalConfigSettingsKey]?.sortAttribute,
      ),
      map((r) => (r ? { active: r.attribute, direction: r.direction } : null)),
      take(1),
      shareReplay({ refCount: true, bufferSize: 1 }),
    ),
  );

  private _id? = new Subject<string>();

  readonly vm$ = this.select(this.state$, (state) => state);

  readonly loading$ = this.select(
    this.state$,
    (state) => state.loading === true,
  );
  readonly deleteItem = this.effect((item$: Observable<T>) =>
    item$.pipe(
      switchMap((item: T) => {
        return (this.dataSource as DefaultCRUDDataSource<T>)
          .deleteById(item.id)
          .pipe(
            tap(() => {
              this._notificationService?.showNotification(
                'Der Eintrag wurde gelöscht',
              );
              this.reload();
            }),
          );
      }),
    ),
  );

  private readonly loadCurrentPage = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => this.patchState({ loading: true })),
      debounceTime(300),
      withLatestFrom(this.state$),
      map((r) => r[1]),
      switchMap(
        ({
          currentPageSize,
          currentPageIndex,
          sort,
          sortDirection,
          filterFields,
          filterValue,
          institutions,
          departments,
          users,
          queryParameters,
          idEndpoint,
        }) => {
          return this.dataSource
            .getPaginated({
              filter: {
                page: currentPageIndex,
                pageSize: currentPageSize,
                sort,
                sortDirection,
                filterFields,
                filterValue,
              },
              instIds: institutions?.map((i) => i.id),
              departmentIds: departments?.map((d) => d.id),
              userIds: users?.map((u) => u.id),
              q: {
                ...queryParameters,
              },
              endpoint: idEndpoint,
            })
            .pipe(
              catchError((err) => {
                console.error(err);
                throw err;
              }),
              tapResponse(
                ({ content, totalElements }) =>
                  this.patchState({
                    totalElementCount: totalElements,
                    content,
                    loading: false,
                  }),
                () => {
                  this.patchState({ loading: false });
                  this._notificationService.showNotification(
                    'Fehler beim Laden der Daten, überprüfen Sie Ihre Internetverbindung.',
                  );
                },
              ),
            );
        },
      ),
    ),
  );

  readonly init = this.loadCurrentPage;
  readonly reload = this.loadCurrentPage;
  readonly paginatorUpdate = this.effect(
    (pagination$: Observable<PaginatorChangeEvent>) =>
      pagination$.pipe(
        tap((r: PaginatorChangeEvent) => {
          this.patchState({
            currentPageSize: r.pageSize,
            currentPageIndex: r.page,
            loading: true,
          });
          this.loadCurrentPage();
        }),
      ),
  );

  readonly sortUpdate = this.effect((sort$: Observable<SortChangeEvent>) => {
    return sort$.pipe(
      tap((r: SortChangeEvent) => {
        this.patchState({ sort: r.sort, sortDirection: r.sortDirection });
        this.loadCurrentPage();

        if (!this._disableFilterPersistence) {
          if (r.sortDirection === 'asc' || r.sortDirection === 'desc') {
            this._personalConfigService.updateFilterSetting(
              this._personalConfigSettingsKey,
              {
                sortAttribute: {
                  attribute: r.sort,
                  direction: r.sortDirection ?? 'asc',
                },
              },
            );
          }
        }
      }),
    );
  });

  readonly filterUpdate = this.effect(
    (filter$: Observable<FilterChangeEvent>) =>
      filter$.pipe(
        tap((r: FilterChangeEvent) => {
          this.patchState({
            filterFields: r.filterFields,
            filterValue: r.filterValue,
            currentPageIndex: 0,
          });
          this.loadCurrentPage();
        }),
      ),
  );

  readonly queryParametersUpdate = this.effect(
    (queryParameters$: Observable<qry>) =>
      queryParameters$.pipe(
        tap((r: qry) => {
          this.patchState({
            queryParameters: r,
            currentPageIndex: 0,
          });
          this.loadCurrentPage();
        }),
      ),
  );

  readonly institutionFilterUpdate = this.effect(
    (
      institutions$: Observable<
        PersonalConfigFilterSetting['filterInstitutions']
      >,
      config?: { dontSave: boolean },
    ) =>
      institutions$.pipe(
        tap((r: PersonalConfigFilterSetting['filterInstitutions']) => {
          this.patchState({ institutions: r, currentPageIndex: 0 });
          this.loadCurrentPage();
          if (
            !config?.dontSave &&
            this._personalConfigSettingsKey &&
            !this._disableFilterPersistence
          ) {
            this._personalConfigService.updateFilterSetting(
              this._personalConfigSettingsKey,
              {
                filterInstitutions: r,
              },
            );
          }
        }),
      ),
  );

  readonly departmentFilterUpdate = this.effect(
    (
      departments$: Observable<
        PersonalConfigFilterSetting['filterDepartments']
      >,
      config?: { dontSave: boolean },
    ) =>
      departments$.pipe(
        tap((r: PersonalConfigFilterSetting['filterDepartments']) => {
          this.patchState({ departments: r, currentPageIndex: 0 });
          this.loadCurrentPage();
          if (
            !config?.dontSave &&
            this._personalConfigSettingsKey &&
            !this._disableFilterPersistence
          ) {
            this._personalConfigService.updateFilterSetting(
              this._personalConfigSettingsKey,
              {
                filterDepartments: r,
              },
            );
          }
        }),
      ),
  );

  readonly userFilterUpdate = this.effect(
    (
      users$: Observable<PersonalConfigFilterSetting['filterUsers']>,
      config?: { dontSave: boolean },
    ) =>
      users$.pipe(
        tap((r: PersonalConfigFilterSetting['filterUsers']) => {
          this.patchState({ users: r, currentPageIndex: 0 });
          this.loadCurrentPage();
          if (
            !config?.dontSave &&
            this._personalConfigSettingsKey &&
            !this._disableFilterPersistence
          ) {
            this._personalConfigService.updateFilterSetting(
              this._personalConfigSettingsKey,
              {
                filterUsers: r,
              },
            );
          }
        }),
      ),
  );

  readonly currentInstitutions$ = this.select(
    this.state$,
    (state) => state.institutions,
  );
  readonly currentDepartments$ = this.select(
    this.state$,
    (state) => state.departments,
  );
  readonly currentUsers$ = this.select(this.state$, (state) => state.users);

  readonly initialFilter$ = combineLatest([
    this._personalConfigService.personalConfig$.pipe(
      filterNotNullOrUndefined(),
      take(1),
    ),
    iif(
      () => this._disableFilterPersistence,
      of(null),
      this._personalConfigSettingsKey$?.pipe(
        filterNotNullOrUndefined(),
        take(1),
      ),
    ),
  ]).pipe(
    map(([config, key]) => {
      if (!key) {
        return {
          filterInstitutions: [],
          filterDepartments: [],
          filterUsers: [],
        } as PersonalConfigFilterSetting;
      }
      return config.filterSettings[key] as PersonalConfigFilterSetting;
    }),
  );

  constructor(
    readonly dataSource: DataTableDataSource<T>,
    _dataTableService: DataTableService,
    @Optional() private readonly _notificationService: NotificationService,
  ) {
    super(null);
    this._id
      .pipe(
        filterNotNullOrUndefined(),
        distinctUntilChanged(),
        switchMap((id) =>
          _dataTableService.reload$.pipe(
            filter((reloadId) => reloadId === id),
            tap(() => this.loadCurrentPage()),
          ),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._id?.complete();
    super.ngOnDestroy();
  }

  setId(id?: string): void {
    this._id?.next(id);
  }
}
