import { Component, inject, Input } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BackgroundTaskService } from '../services/background-task-service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ arrayName: 'subs' })
@Component({
  selector: 'tremaze-progress-display',
  templateUrl: './progress-display.component.html',
  styleUrls: ['./progress-display.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleSizeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ maxHeight: '82px' })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ maxHeight: 0 }), animate('0.2s ease')]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate('0.2s ease', style({ maxHeight: 0 }))),
    ]),
  ],
})
export class ProgressDisplayComponent {
  readonly service = inject(BackgroundTaskService);

  @Input() hide = false;
  expanded = true;

  onClickExpandButton() {
    this.expanded = !this.expanded;
  }
}
