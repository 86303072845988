import { Observable } from 'rxjs';

export abstract class AppConfigService {
  abstract readonly basePath: string | Observable<string>;

  abstract readonly version: string;

  abstract readonly state: APP_CONFIG_STAGES;

  abstract readonly clientId: string;

  abstract readonly productName?: string;

  abstract readonly logoPaths?: { light: string; dark: string; icon?: string };

  readonly disablePermissionChecks?: boolean;

  abstract readonly disableFreshChat?: boolean;

  get isProd() {
    return this.state === 'PROD';
  }
}

export type APP_CONFIG_STAGES = 'PROD' | 'DEV' | 'QS';
