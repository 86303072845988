import {
  Component,
  EventEmitter,
  inject,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfViewerComponent } from './pdf-viewer.component';
import { Placeholder } from '@tremaze/placeholder';

@Component({
  selector: 'tremaze-pdf-viewer-dialog',
  template: `
    <div style="display: flex; flex-direction: column; height: 100%">
      <tremaze-pdf-viewer
        [canReplacePlaceholders]="canReplacePlaceholders"
        [canWrite]="canWrite"
        [documentData]="documentData"
        [documentName]="name"
        [documentUrl]="url"
        [placeholders]="placeholders"
        style="flex: 1;"
      ></tremaze-pdf-viewer>
      <div align="end" mat-dialog-actions>
        <button (click)="pdfViewer?.print()" mat-button>Drucken</button>
        @if (canWrite || canReplacePlaceholders) {
          <button mat-button (click)="onClickedSave()">Speichern</button>
        }
        <button mat-button mat-dialog-close>Schließen</button>
      </div>
    </div>
  `,
  styleUrl: './pdf-viewer-dialog.component.scss',
})
export class PdfViewerDialogComponent {
  readonly url = inject(MAT_DIALOG_DATA).documentUrl;
  readonly documentData = inject(MAT_DIALOG_DATA).documentData;
  readonly name = inject(MAT_DIALOG_DATA).documentName;
  readonly placeholders: Placeholder[] =
    inject(MAT_DIALOG_DATA).placeholders ?? [];
  readonly canWrite = inject(MAT_DIALOG_DATA).canWrite;
  readonly canReplacePlaceholders =
    inject(MAT_DIALOG_DATA).canReplacePlaceholders;

  @ViewChild(PdfViewerComponent, { static: true })
  pdfViewer?: PdfViewerComponent;

  @Output() readonly save = new EventEmitter<Blob>();

  async onClickedSave() {
    const blob = await this.pdfViewer?.save();
    if (blob) {
      this.save.emit(blob);
    }
  }
}
