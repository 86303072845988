import {
  BaseTenantConfig,
  EventConfig,
  FullTenantConfig,
  InformationConfig,
  TremazeModuleName,
} from './types';
import { TenantConfigService } from './tenant-config.service';
import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  connectable,
  distinctUntilKeyChanged,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  tap,
  zip,
} from 'rxjs';
import { mapEveryTrue } from '@tremaze/shared/util/rxjs';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { TenantDataSource } from '@tremaze/shared/feature/tenant/data-access';

@Injectable()
export class TenantConfigServiceImpl implements TenantConfigService {
  private readonly _reload$ = new Subject<void>();

  get config$(): Observable<FullTenantConfig> {
    return this._config$;
  }

  private _config: FullTenantConfig | null = null;

  get config(): FullTenantConfig | null {
    return this._config;
  }

  private readonly _config$ = connectable(
    this._reload$.pipe(
      startWith(null),
      mergeMap(() => this._authService.activeTenant$),
      distinctUntilKeyChanged('id'),
      mergeMap(() => this._fetchConfig()),
    ),
    { connector: () => new ReplaySubject(1) },
  );

  readonly hasConfig$ = this._config$.pipe(
    map((config) => !!config),
    startWith(false),
    shareReplay(1),
  );

  readonly fakeAccountsEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('FAKE_ACCOUNT')),
  );

  readonly fakeAccountsAreDefault$ = combineLatest([
    this.fakeAccountsEnabled$,
    this._authService.activeTenant$.pipe(
      map((tenant) => tenant?.fakeAccountsAreDefault ?? false),
    ),
  ]).pipe(mapEveryTrue());

  readonly customFormEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('CUSTOM_FORM')),
  );

  readonly workflowEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('WORKFLOW')),
  );

  readonly goalsEnabled$ = this.config$.pipe(
    map((config) => config.isGoalsEnabled),
  );

  readonly specializationEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('SPECIALIZATION')),
  );

  readonly additionalInfoEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('ADDITIONAL_INFO')),
  );

  readonly additionalInfo2Enabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('ADDITIONAL_INFO_2')),
  );

  readonly eventScheduleEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('EVENT_SCHEDULE')),
  );

  readonly postboxEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('POSTBOX')),
  );

  readonly familyEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('FAMILY')),
  );

  readonly contactPointEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('CONTACT_POINT')),
  );

  readonly chatEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('CHAT')),
  );

  readonly dashboardEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('DASHBOARD')),
  );

  readonly eventPresetsEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('EVENT_PRESETS')),
  );

  readonly approvalEnabled$ = this.config$.pipe(
    map((config) => !config.disabledFeatures.has('APPROVAL')),
  );

  private get _isNotProd() {
    return this._appConfigService.state !== 'PROD';
  }

  reload() {
    this._reload$.next();
    this._authService.reloadActiveTenant();
  }

  constructor(
    private readonly _authService: AuthV2Service,
    private readonly _http: HttpClient,
    private readonly _appConfigService: AppConfigService,
    private readonly _tenantDataSource: TenantDataSource,
  ) {
    this._config$.connect();
  }

  private _getIsGoalsEnabled(): Observable<boolean> {
    return this._http.get('/public/goal/config').pipe(
      map((response: any) => response.enableTasksAndGoals),
      catchError(() => of(false)),
    );
  }

  private _getBaseTenantConfig(): Observable<BaseTenantConfig> {
    return forkJoin({
      config: this._http.get<Record<string, unknown>>('/public/config'),
      tenant: this._tenantDataSource.getTenant(),
    }).pipe(
      map(({ config, tenant }) => {
        return {
          ...config,
          addressFieldsRequired: new Set(
            (config['addressFieldsRequired'] as string[]) ?? [],
          ),
          disabledFeatures: new Set(
            (config['disabledFeatures'] as string[]) ?? [],
          ),
          defaultForceDocumentationPeriodForEvents:
            tenant.defaultForceDocumentationPeriodForEvents,
        } as BaseTenantConfig;
      }),
    );
  }

  private _getConfig<T extends object>(url: string): Observable<T> {
    return this._http.get<T>(url).pipe(catchError(() => of({} as T)));
  }

  private _fetchEventConfig(): Observable<EventConfig> {
    return this._getConfig<EventConfig>('/public/events/config');
  }

  private _fetchInformationConfig(): Observable<InformationConfig> {
    return this._getConfig<InformationConfig>('/public/information/config');
  }

  private _fetchConfig(): Observable<FullTenantConfig> {
    return zip(
      this._getIsGoalsEnabled(),
      this._getBaseTenantConfig(),
      this._fetchEventConfig(),
      this._fetchInformationConfig(),
    ).pipe(
      map(([isGoalsEnabled, tenantConfig, eventConfig, informationConfig]) => {
        return {
          isGoalsEnabled,
          ...eventConfig,
          ...informationConfig,
          ...tenantConfig,
        };
      }),
      tap((config) => {
        this._config = config;
      }),
    );
  }

  isModuleEnabled(moduleName: TremazeModuleName): Observable<boolean> {
    if (moduleName === 'GOAL') {
      return this.goalsEnabled$;
    }
    return this.config$.pipe(
      map((config) => !config.disabledFeatures.has(moduleName)),
    );
  }
}

export const provideTenantConfigService = () => ({
  provide: TenantConfigService,
  useClass: TenantConfigServiceImpl,
});
