import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import {
  AuthenticatedUser,
  AuthV2ModuleConfig,
  AuthV2ModuleConfigInjectionToken,
  OIDConfig,
  TokenInfo,
} from '@tremaze/shared/core/auth-v2/types';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { AppConfigService } from '@tremaze/shared/util-app-config';
import { catchError, first, map, mergeMap, take } from 'rxjs/operators';
import { Address, ContactInfo } from '@tremaze/shared/models';
import { UserInstitution } from '@tremaze/shared/feature/user/feature/allocation/types';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { TremazeDate } from '@tremaze/shared/util-date';
import {
  CredentialsException,
  NotApprovedException,
} from '@tremaze/shared/util-error';
import { AuthV2DataSource } from './shared-core-auth-v2-data-access';
import { Gender } from '@tremaze/shared/feature/gender/types';

@Injectable({ providedIn: 'root' })
export class RemoteOIDCAuthV2DataSource implements AuthV2DataSource {
  private oidCfg$ = new ReplaySubject<OIDConfig>();

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    @Inject(AuthV2ModuleConfigInjectionToken)
    private config: AuthV2ModuleConfig,
  ) {
    if (config?.oidcConfig?.configURL?.length) {
      setTimeout(() => {
        http
          .get<OIDConfig>(config.oidcConfig.configURL, {
            params: { skipTenantId: 'true', skipAuth: 'true' },
          })
          .subscribe((r) => this.oidCfg$.next(r));
      });
    } else {
      throw Error('NO OIDC CONFIG FOUND');
    }
  }

  getAuthenticatedUser(): Observable<AuthenticatedUser> {
    return (
      this.http
        // TODO: Define the correct type
        // eslint-disable-next-line
        .get<any>('/users/self', {})
        .pipe(
          map(
            (u) =>
              new AuthenticatedUser(
                u.id,
                null,
                u.username,
                u.email,
                true,
                false,
                u.userRoles,
                u.userPrivileges,
                u.firstName,
                u.lastName,
                new ContactInfo(u.email, u.mobile, u.phone),
                Gender.deserialize(u.gender),
                u.userInstitutions?.map(UserInstitution.deserialize) ?? [],
                FileStorage.deserialize(u.avatar),
                Address.deserialize(u.address),
                TremazeDate.deserialize(u.birth),
                u.userTypes?.map((ut) => ut.userType) ?? [],
              ),
          ),
        )
    );
  }

  getRefreshedToken(refreshToken: string): Observable<TokenInfo> {
    return this.oidCfg$.pipe(
      first(),
      mergeMap((cfg) => {
        const body = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${this.appConfigService?.clientId}`;
        return this.http
          .post<TokenInfo>(cfg.token_endpoint, body, {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            params: { skipTenantId: 'true', skipAuth: 'true' },
          })
          .pipe(
            map((d) => TokenInfo.deserialize(d)),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 400 && err.error.error === 'invalid_grant') {
                return throwError(() => new CredentialsException());
              }
              return throwError(() => err);
            }),
          );
      }),
    );
  }

  loginWithUsernamePassword(
    username: string,
    password: string,
  ): Observable<TokenInfo> {
    return this.oidCfg$.pipe(
      take(1),
      mergeMap((cfg) => {
        const formData = new URLSearchParams();
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);
        formData.append('client_id', this.appConfigService?.clientId);
        return this.http
          .post<TokenInfo>(cfg.token_endpoint, formData, {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            params: { skipTenantId: 'true', skipAuth: 'true' },
          })
          .pipe(
            map((d) => TokenInfo.deserialize(d)),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 400 && err.error.error === 'invalid_grant') {
                if (err.error.error_description == 'User account is locked') {
                  return throwError(() => new NotApprovedException());
                }

                return throwError(() => new CredentialsException());
              }
              return throwError(() => err);
            }),
          );
      }),
    );
  }
}
