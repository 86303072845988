import { Injectable } from '@angular/core';
import { Pagination } from '@tremaze/shared/models';
import { DataSourceMethodsPaginatedOptions } from '@tremaze/shared/util-http';
import { Observable, of } from 'rxjs';
import { ApprovalService } from '@tremaze/shared/feature/approval/types';
import { ApprovalServiceFixtures } from '@tremaze/fixtures';
import { ApprovalServiceDataSource } from '../approval-service-data-source';
import { PartialBy } from '@tremaze/shared/util/types';

@Injectable({
  providedIn: 'root',
})
export class MockApprovalServiceDataSource
  implements ApprovalServiceDataSource
{
  getIsApprovalShortNameAvailable(
    institutionId: string,
    shortName: string,
  ): Observable<boolean> {
    return of(true);
  }

  getApprovalServicesForUser(userId: string): Observable<ApprovalService[]> {
    return of([
      ApprovalServiceFixtures.approvalService1,
      ApprovalServiceFixtures.approvalService2,
      ApprovalServiceFixtures.approvalService3,
    ]);
  }

  createApprovalService(
    approvalService: Omit<ApprovalService, 'id' | 'subApprovalServices'> & {
      subApprovalServices?: PartialBy<ApprovalService, 'id'>[];
    },
    institutionId: string,
  ): Observable<ApprovalService> {
    return of(ApprovalServiceFixtures.approvalService1);
  }

  updateApprovalService(
    approvalService: Omit<ApprovalService, 'subApprovalServices'> & {
      subApprovalServices?: PartialBy<ApprovalService, 'id'>[];
    },
  ): Observable<ApprovalService> {
    return of(approvalService as ApprovalService);
  }

  deleteApprovalService(id: string): Observable<boolean> {
    return of(true);
  }

  getPaginatedApprovalServices(
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<ApprovalService>> {
    return of(ApprovalServiceFixtures.generateApprovalServicePage(options));
  }

  getPaginatedApprovalServicesForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions<any>,
  ): Observable<Pagination<ApprovalService>> {
    return of(ApprovalServiceFixtures.generateApprovalServicePage(options));
  }

  getApprovalServiceById(id: string): Observable<ApprovalService> {
    return of(ApprovalServiceFixtures.generateApprovalService(parseInt(id)));
  }

  getApprovalServiceByName(name: string): Observable<ApprovalService> {
    return of(ApprovalServiceFixtures.generateApprovalService(1));
  }
}

export const provideMockApprovalServiceDataSource = () => ({
  provide: ApprovalServiceDataSource,
  useClass: MockApprovalServiceDataSource,
});
