import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import * as TenantActions from './tenant.actions';
import * as TenantSelectors from './tenant.selectors';
import { Observable } from 'rxjs';
import { Tenant } from '@tremaze/shared/feature/tenant/types';

@Injectable({ providedIn: 'root' })
export class TenantFacade {
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$: Observable<boolean> = this.store.pipe(
    select(TenantSelectors.getTenantLoaded),
  );
  allTenant$ = this.store.pipe(select(TenantSelectors.getAllTenant));
  selectedTenant$: Observable<Tenant | null> = this.store.pipe(
    select(TenantSelectors.getSelected),
  );
  projects$ = this.store.pipe(select(TenantSelectors.getProjects));

  constructor(private store: Store) {}

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(TenantActions.init());
  }

  loadProjects() {
    this.store.dispatch(TenantActions.loadTenantProjects());
  }
}
