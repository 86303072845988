import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Provider,
  Type,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StorageInjectionToken } from '@tremaze/shared/core/storage';
import { LocalStorage } from '@tremaze/shared/core/storage/local-storage';
import { CookieStorage } from '@tremaze/shared/core/storage/cookie-storage';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthV2DataSource } from '@tremaze/shared/core/auth-v2/data-access';
import {
  AuthV2ModuleConfig,
  AuthV2ModuleConfigInjectionToken,
} from '@tremaze/shared/core/auth-v2/types';
import { AuthV2Guard } from './auth-v2.guard';
import { NoAuthV2Guard } from './no-auth-v2.guard';
import { AuthV2Service, WHITELISTED_USER_TYPES } from './auth-service';
import { AuthOidcService } from './auth-oidc.service';
import { DeleteMscacheGuard } from './delete-mscache.guard';

@NgModule({
  imports: [CommonModule],
})
export class SharedCoreAuthV2Module {
  static forRoot(
    authDataSource: Type<AuthV2DataSource>,
    config?: AuthV2ModuleConfig,
  ): ModuleWithProviders<SharedCoreAuthV2Module> {
    let providers: Provider[] = [
      AuthV2Guard,
      NoAuthV2Guard,
      authDataSource,
      DeleteMscacheGuard,
    ];
    if (config?.whitelistedUserTypes) {
      providers = [
        ...providers,
        {
          provide: WHITELISTED_USER_TYPES,
          useValue: config.whitelistedUserTypes,
        },
      ];
    }
    if (config?.useCookieStorage) {
      providers = [
        ...providers,
        { provide: StorageInjectionToken, useClass: CookieStorage },
      ];
    } else {
      providers = [
        ...providers,
        { provide: StorageInjectionToken, useClass: LocalStorage },
      ];
    }
    if (config) {
      providers = [
        ...providers,
        {
          provide: AuthV2ModuleConfigInjectionToken,
          useValue: config,
        },
      ];
    }
    return {
      ngModule: SharedCoreAuthV2Module,
      providers: [
        ...providers,
        {
          provide: AuthV2Service,
          useClass: AuthOidcService,
        },
        { provide: AuthV2DataSource, useExisting: authDataSource },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (ds: AuthV2Service) => () => ds.init(),
          deps: [AuthV2Service],
          multi: true,
        },
      ],
    };
  }
}
