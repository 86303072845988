import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { Placeholder } from '../types';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@tremaze/shared/notification';

@Component({
  selector: 'tremaze-placeholder-table',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    IconComponent,
    MatTooltip,
  ],
  templateUrl: './placeholder-table.component.html',
  styleUrl: './placeholder-table.component.scss',
})
export class PlaceholderTableComponent {
  private readonly _clipboard = inject(Clipboard);
  private readonly _notification = inject(NotificationService);

  @Input({ required: true }) placeholders: Placeholder[] = [];

  get showValues() {
    return this.placeholders.some((p) => p.value);
  }

  @Output() readonly fill = new EventEmitter<void>();

  onClickCopy(placeholder: Placeholder) {
    this._clipboard.copy(placeholder.name);
    this._notification.showNotification(
      'Der Platzhalter wurde in die Zwischenablage kopiert.',
    );
  }
}
