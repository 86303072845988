import {
  DataSourceMethodsBaseOptions,
  DataSourceMethodsCreateOptions,
  DataSourceMethodsEditOptions,
  DataSourceMethodsGetFreshOptions,
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSource,
} from '@tremaze/shared/util-http';
import {
  CustomForm,
  CustomFormExportSchema,
  CustomFormExportSchemaPlaceholders,
  CustomFormSubmission,
} from '@tremaze/shared/feature/custom-forms/types';
import { Observable } from 'rxjs';
import { Pagination } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';

type CustomFormCreateOptions = DataSourceMethodsCreateOptions<CustomForm>;

export abstract class CustomFormDataSource
  implements
    Pick<
      DefaultCRUDDataSource<CustomForm>,
      | 'create'
      | 'edit'
      | 'getManyFreshByIds'
      | 'getFreshById'
      | 'getPaginated'
      | 'deleteById'
    >
{
  abstract deleteById(
    id: string,
    options?: DataSourceMethodsBaseOptions | undefined,
  ): Observable<boolean>;

  abstract edit(
    i: CustomForm,
    options?: DataSourceMethodsEditOptions<CustomForm> | undefined,
  ): Observable<CustomForm>;

  abstract getManyFreshByIds(
    ids: string[],
    options?: DataSourceMethodsGetFreshOptions<CustomForm> | undefined,
  ): Observable<CustomForm[]>;

  abstract getFreshById(
    id: string,
    options?: DataSourceMethodsGetFreshOptions<any> | undefined,
  ): Observable<CustomForm>;

  abstract getPaginated(
    options?: DataSourceMethodsPaginatedOptions<any> | undefined,
  ): Observable<Pagination<CustomForm>>;

  abstract create(
    i: CustomForm,
    options?: CustomFormCreateOptions,
  ): Observable<CustomForm>;

  abstract setPublished(
    formId: string,
    published: boolean,
  ): Observable<boolean>;

  abstract getFilledOutFormForUser(
    formId: string,
    userId: string,
  ): Observable<CustomFormSubmission | null>;

  abstract removeFormFromInstitution(
    formId: string,
    institutionId: string,
  ): Observable<boolean>;

  abstract setCSVExportTemplate(
    formId: string,
    template: string,
  ): Observable<boolean>;

  abstract getCSVExportTemplate(formId: string): Observable<string>;

  abstract getFormsByNames(names: string[]): Observable<CustomForm[]>;

  abstract getAvailablePlaceholdersForForm(
    formId: string,
  ): Observable<CustomFormExportSchemaPlaceholders>;

  abstract getExportSchemaForForm(
    formId: string,
  ): Observable<CustomFormExportSchema | null>;

  abstract setExportSchemaForForm(
    formId: string,
    schema: CustomFormExportSchema | null,
  ): Observable<CustomFormExportSchema | null>;

  abstract setPdfExportTemplateFile(
    formId: string,
    fileId: string | null,
  ): Observable<boolean>;

  abstract getPdfExportTemplateFile(
    formId: string,
  ): Observable<FileStorage | null>;

  abstract exportFormSubmissions(
    formId: string,
    startDate: TremazeDate,
    endDate: TremazeDate,
    institutionIds?: string[],
  ): Observable<Blob>;
}
