import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PriceLevel } from '@tremaze/shared/feature/event/types';
import { Observable } from 'rxjs';
import { PriceLevelSettingComponent } from './price-level-setting.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PriceLevelSettingService {
  constructor(private dialog: MatDialog) {}

  setPriceLevels(initialValue?: PriceLevel[]): Observable<PriceLevel[]> {
    return this.dialog
      .open(PriceLevelSettingComponent, { data: { initialValue } })
      .afterClosed()
      .pipe(map((r) => (Array.isArray(r) ? r : initialValue)));
  }
}
