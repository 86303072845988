import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFormEditComponent } from './custom-form-edit.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomFormsFieldEditComponent } from './fields/custom-forms-field-edit.component';
import { CustomFormFieldsTextboxFieldEditComponent } from './fields/custom-form-fields-textbox-field-edit/custom-form-fields-textbox-field-edit.component';
import { MatSelectModule } from '@angular/material/select';
import { CustomFormFieldsFileEditComponent } from './fields/custom-form-fields-file-edit/custom-form-fields-file-edit.component';
import { CustomFormFieldsMultiselectEditComponent } from './fields/custom-form-fields-multiselect-edit/custom-form-fields-multiselect-edit.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomFormAddNewFieldButtonComponent } from './custom-form-add-new-field-button/custom-form-add-new-field-button.component';
import { MatMenuModule } from '@angular/material/menu';
import { CustomFormEditService } from './edit.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { CustomFormEditDialogComponent } from './custom-form-edit-dialog/custom-form-edit-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SharedPermissionUiDirectivesModule } from '@tremaze/shared/permission/ui/directives';
import { ReportTemplateDialogComponent } from './report-template/report-template-dialog.component';
import { CustomFormFeatureSelectComponent } from '@tremaze/custom-form-feature-select';
import { IconComponent } from '@tremaze/shared/ui/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { PdfViewerModule } from '@tremaze/shared/feature/pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatMenuModule,
    DragDropModule,
    MatDialogModule,
    MatProgressBarModule,
    MatListModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    SharedPermissionUiDirectivesModule,
    PdfViewerModule,
    FormsModule,
    CustomFormFeatureSelectComponent,
    IconComponent,
    MatTooltip,
  ],
  declarations: [
    CustomFormEditComponent,
    CustomFormsFieldEditComponent,
    CustomFormFieldsTextboxFieldEditComponent,
    CustomFormFieldsFileEditComponent,
    CustomFormFieldsMultiselectEditComponent,
    CustomFormAddNewFieldButtonComponent,
    CustomFormEditDialogComponent,
    ReportTemplateDialogComponent,
  ],
  exports: [CustomFormEditComponent],
  providers: [CustomFormEditService],
})
export class SharedFeatureCustomFormsFeatureEditModule {}
