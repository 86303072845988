import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomFormEditService } from '../edit.service';
import { NotificationService } from '@tremaze/shared/notification';
import { FormControl } from '@angular/forms';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { PdfViewerService } from '@tremaze/shared/feature/pdf-viewer';
import { FileStorageService } from '@tremaze/shared/feature/file-storage/services';
import { combineLatest, switchMap, take, takeUntil, tap } from 'rxjs';
import { CustomFormDataSource } from '@tremaze/shared/feature/custom-forms/data-access';
import { CustomFormPlaceholderHelpers } from '@tremaze/shared/feature/custom-forms/utils';
import { doOnError } from '@tremaze/shared/util/rxjs';
import { blobToFile } from '@tremaze/shared/util-utilities';

@Component({
  selector: 'tremaze-report-template-dialog',
  templateUrl: './report-template-dialog.component.html',
})
export class ReportTemplateDialogComponent {
  private readonly _customFormId: string = inject(MAT_DIALOG_DATA);
  private readonly _formEditService = inject(CustomFormEditService);
  private readonly _formDataSource = inject(CustomFormDataSource);
  private readonly _notificationService = inject(NotificationService);
  private readonly _pdfViewerService = inject(PdfViewerService);
  private readonly _fileStorageService = inject(FileStorageService);

  readonly pdfTemplateFormControl = new FormControl<FileStorage | null>(null);

  constructor() {
    this._formEditService
      .getPdfTemplate(this._customFormId)
      .pipe(tap((r) => this.pdfTemplateFormControl.setValue(r)))
      .subscribe();
  }

  get buttonLabel() {
    if (this.pdfTemplateFormControl.value) {
      return this.pdfTemplateFormControl.value.fileViewname;
    }
    return 'Datei auswählen';
  }

  onFileChanged(event: Event) {
    const fileList = (event.target as HTMLInputElement).files;
    if (fileList && fileList.length === 1) {
      this._formEditService
        .uploadPdfTemplate(this._customFormId, fileList[0])
        .pipe(
          doOnError((e) =>
            this._notificationService.showDefaultErrorNotification(),
          ),
          tap((r) => {
            this.pdfTemplateFormControl.setValue(r);
            this.pdfTemplateFormControl.markAsDirty();
            this._notificationService.showNotification('Datei hochgeladen');
            this.onClickEditPdfTemplate();
          }),
        )
        .subscribe();
    }
  }

  onClickSubmitButton() {
    const value = this.pdfTemplateFormControl.value;
    this._formEditService
      .setPdfTemplate(this._customFormId, value?.id ?? null)
      .pipe(
        doOnError((e) =>
          this._notificationService.showDefaultErrorNotification(),
        ),
        tap(() => {
          this._notificationService.showNotification('Vorlage gespeichert');
          this.pdfTemplateFormControl.markAsPristine();
        }),
      )
      .subscribe();
  }

  onClickEditPdfTemplate() {
    const value = this.pdfTemplateFormControl.value;
    if (value) {
      combineLatest([
        this._formDataSource.getFreshById(this._customFormId),
        this._fileStorageService.getFileDownloadURL(value),
      ])
        .pipe(
          take(1),
          tap(([form, url]) => {
            const ref = this._pdfViewerService.openPDF({
              pdfUrl: url,
              pdfName: value.fileViewname,
              placeholders: CustomFormPlaceholderHelpers.mapFormToPlaceholders(
                form,
                {
                  includeFilledOutBy: true,
                  includeFilledOutFor: true,
                },
              ),
              canWrite: true,
            });

            ref.componentInstance.save
              .pipe(
                takeUntil(ref.afterClosed()),
                switchMap((r) =>
                  this._fileStorageService.overwriteFile(
                    value.id,
                    blobToFile(r, value.fileViewname, value.fileType),
                  ),
                ),
                tap(() => {
                  if (this.pdfTemplateFormControl.dirty) {
                    this.onClickSubmitButton();
                  }
                }),
              )
              .subscribe();
          }),
        )
        .subscribe();
    }
  }

  onClickDeletePdfTemplate() {
    this.pdfTemplateFormControl.setValue(null);
    this.pdfTemplateFormControl.markAsDirty();
  }
}
