import { CustomFormFieldType } from './form-field-types';
import { Meta } from '@tremaze/shared/models';
import { CustomFormFieldValue } from './values/value';

export interface CustomFormField<T> {
  readonly id: string;
  readonly meta: Meta;
  readonly fieldType: CustomFormFieldType;
  readonly name: string;
  readonly label: string;
  readonly required: boolean;
  readonly sort: number;
  readonly json_id: string;
  readonly filledOutValues?:
    | CustomFormFieldValue<T>
    | CustomFormFieldValue<T>[];
}
