import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilKeyChanged,
  filter,
  map,
  merge,
  Observable,
  of,
  tap,
} from 'rxjs';
import {
  DirStorage,
  FileStorage,
} from '@tremaze/shared/feature/file-storage/types';
import { HttpClient } from '@angular/common/http';
import { AuthV2Service } from '@tremaze/shared/core/auth-v2';
import { filterNotNullOrUndefined } from '@tremaze/shared/util/rxjs';

export type FileStoragePermission = 'READ' | 'WRITE' | 'DELETE';

@Injectable({
  providedIn: 'root',
})
export class FileStoragePermissionsService {
  private _dirCache: BehaviorSubject<{
    [dirId: string]: FileStoragePermission[] | 'LOADING';
  }> = new BehaviorSubject({});
  private _fileCache: BehaviorSubject<{
    [fileId: string]: FileStoragePermission[] | 'LOADING';
  }> = new BehaviorSubject({});

  constructor(
    private readonly _http: HttpClient,
    private _authService: AuthV2Service,
  ) {
    // Subscribe to changes in authenticated user or active tenant and clear caches when they change
    merge([
      this._authService.authenticatedUser$.pipe(
        distinctUntilKeyChanged('userId'),
      ),
      this._authService.activeTenant$.pipe(
        filterNotNullOrUndefined(),
        distinctUntilKeyChanged('id'),
      ),
    ]).subscribe(() => {
      this._clearCaches();
    });
  }

  /**
   * Clears the directory and file permission caches.
   */
  private _clearCaches(): void {
    this._dirCache.next({});
    this._fileCache.next({});
  }

  /**
   * Retrieves permissions from the cache for a given directory or file ID.
   * If permissions are loading or not found, returns null.
   *
   * @param cache - The cache to retrieve permissions from (directory or file).
   * @param id - The ID of the directory or file.
   * @returns An observable of the permissions, or null if not available.
   */
  private _getPermissionsFromCache(
    cache: BehaviorSubject<{
      [id: string]: FileStoragePermission[] | 'LOADING';
    }>,
    id: string,
  ): Observable<FileStoragePermission[]> | null {
    const currentCache = cache.value[id];
    if (currentCache && currentCache !== 'LOADING') {
      return of(currentCache);
    } else if (currentCache === 'LOADING') {
      return cache.pipe(
        map((cache) => cache[id]),
        filter(
          (cache): cache is FileStoragePermission[] =>
            !!cache && cache !== 'LOADING',
        ),
      );
    }
    cache.next({ ...cache.value, [id]: 'LOADING' });
    return null;
  }

  /**
   * Gets permissions for a specified directory from the cache or makes an HTTP request if not cached.
   *
   * @param id - The ID of the directory.
   * @returns An observable of the directory permissions.
   */
  getPermissionsForDir(id: string): Observable<FileStoragePermission[]> {
    const cachedPermissions = this._getPermissionsFromCache(this._dirCache, id);
    if (cachedPermissions) {
      return cachedPermissions;
    }
    this._http
      .get<FileStoragePermission[]>(`dirs/${id}/permissions`)
      .pipe(
        tap((perms) => {
          this._dirCache.next({
            ...this._dirCache.value,
            [id]: perms,
          });
        }),
      )
      .subscribe();

    return this.getPermissionsForDir(id);
  }

  /**
   * Gets permissions for a specified file from the cache or makes an HTTP request if not cached.
   *
   * @param id - The ID of the file.
   * @returns An observable of the file permissions.
   */
  getPermissionForFile(id: string): Observable<FileStoragePermission[]> {
    const cachedPermissions = this._getPermissionsFromCache(
      this._fileCache,
      id,
    );
    if (cachedPermissions) {
      return cachedPermissions;
    }
    this._http
      .get<FileStoragePermission[]>(`files/${id}/permissions`)
      .pipe(
        tap((perms) => {
          this._fileCache.next({
            ...this._fileCache.value,
            [id]: perms,
          });
        }),
      )
      .subscribe();

    return this.getPermissionForFile(id);
  }

  /**
   * Checks if a target storage (file or directory) has a specific permission.
   *
   * @param target - The target storage (file or directory).
   * @param parent - The parent directory of the target.
   * @param permission - The permission to check ('READ', 'WRITE', or 'DELETE').
   * @returns An observable indicating whether the target has the specified permission.
   */
  private _hasPermissionForStorage(
    target: FileStorage | DirStorage,
    parent: DirStorage,
    permission: FileStoragePermission,
  ): Observable<boolean> {
    if (target instanceof FileStorage && parent.absolutePath === '/') {
      return of(true);
    }

    const targetId = target instanceof DirStorage ? target.id : parent.id;
    return this.getPermissionsForDir(targetId).pipe(
      map((perms) => perms.includes(permission)),
    );
  }

  /**
   * Checks if the specified storage has delete permission.
   *
   * @param target - The target storage (file or directory).
   * @param parent - The parent directory of the target.
   * @returns An observable indicating whether the target has delete permission.
   */
  hasDeletePermissionForStorage(
    target: FileStorage | DirStorage,
    parent: DirStorage,
  ): Observable<boolean> {
    return this._hasPermissionForStorage(target, parent, 'DELETE');
  }

  /**
   * Checks if the specified storage has write permission.
   *
   * @param target - The target storage (file or directory).
   * @param parent - The parent directory of the target.
   * @returns An observable indicating whether the target has write permission.
   */
  hasWritePermissionForStorage(
    target: FileStorage | DirStorage,
    parent: DirStorage,
  ): Observable<boolean> {
    return this._hasPermissionForStorage(target, parent, 'WRITE');
  }
}
