import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PriceLevel } from '@tremaze/shared/feature/event/types';
import { FormArray, FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'tremaze-price-level-setting',
  template: `
    <span mat-dialog-title>Preisklassen</span>
    <div
      mat-dialog-content
      [formGroup]="formGroup"
      style="width: 600px"
      [@.disabled]="!animationBugWorkaroundState"
    >
      <mat-accordion formArrayName="levels">
        <mat-expansion-panel
          *ngFor="let priceLevelControl of formArray.controls; let i = index"
          [formGroupName]="i"
        >
          <mat-expansion-panel-header>
            <ng-container
              *ngIf="priceLevelControl.touched || priceLevelControl.valid"
            >
              <mat-icon style="margin-right: 10px">
                <span
                  class="lnr lnr-checkmark-circle"
                  *ngIf="priceLevelControl.valid; else err"
                ></span>
                <ng-template #err>
                  <span class="lnr lnr-notification"></span>
                </ng-template>
              </mat-icon>
            </ng-container>
            <mat-panel-title
              >{{ priceLevelControl.value?.name || 'Kein Name' }} -
              {{ priceLevelControl?.value?.price || 0 }}€
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="form-grid">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" required />
              <mat-error *ngIf="priceLevelControl.controls.name.invalid"
                >Bitte gib einen Namen an
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Preis</mat-label>
              <input
                matInput
                type="number"
                min="0"
                formControlName="price"
                required
              />
              <mat-icon matSuffix>euro</mat-icon>
              <mat-error *ngIf="priceLevelControl.controls.price.invalid"
                >Bitte gib einen gültigen Wert an
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Reservierte Plätze</mat-label>
              <input
                matInput
                type="number"
                min="0"
                formControlName="attendeesSpace"
                required
              />
              <mat-hint
                >Diese Plätze sind Teil der Max. Teilnehmer der Veranstaltung
              </mat-hint>
              <mat-error
                *ngIf="priceLevelControl.controls.attendeesSpace.invalid"
                >Bitte gib die Anzahl der reservierten Plätze an
              </mat-error>
            </mat-form-field>
            <mat-form-field class="form-grid-item-full-width">
              <mat-label>Beschreibungstext</mat-label>
              <tremaze-rich-text-editor
                formControlName="description"
                style="display: block; min-height: 400px; height: 400px"
                required=""
              ></tremaze-rich-text-editor>
              <mat-hint
                >Teile den Besuchern hierüber mit, wie man sich für diese
                Preisklasse qualifiziert.
              </mat-hint>
              <mat-error *ngIf="priceLevelControl.controls.description.invalid"
                >Bitte gib einen Beschreibungstext an
              </mat-error>
            </mat-form-field>
            <button
              mat-raised-button
              color="warn"
              (click)="onClickRemovePriceLevelAtIndexButton(i)"
            >
              Entfernen
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <button
        mat-stroked-button
        style="margin-top: 10px; width: 100%"
        color="accent"
        (click)="onClickAddPriceLevelButton()"
      >
        Preisklasse hinzufügen
      </button>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Abbrechen</button>
      <button mat-raised-button color="accent" (click)="onClickSubmitButton()">
        Speichern
      </button>
    </div>
  `,
  styles: [
    `
      mat-expansion-panel-header mat-icon {
        font-size: 20px;
        display: inline-flex;
        align-items: center;
      }

      mat-expansion-panel-header .lnr-notification {
        color: red;
      }

      mat-expansion-panel-header .lnr-checkmark-circle {
        color: green;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PriceLevelSettingComponent implements OnInit, AfterViewInit {
  constructor(
    private fb: FormBuilder,
    private ref: MatDialogRef<PriceLevelSettingComponent>,
    @Inject(MAT_DIALOG_DATA) private data?: { initialValue: PriceLevel[] },
  ) {}

  get formArray(): FormArray<FormGroup<PriceLevel>> {
    return this.formGroup.controls.levels as FormArray<FormGroup<PriceLevel>>;
  }
  formGroup: FormGroup<PriceLevelSettingFormModel>;

  animationBugWorkaroundState = false;

  ngOnInit(): void {
    this.formGroup = this.fb.group<PriceLevelSettingFormModel>({
      levels: this.fb.array<FormGroup<PriceLevel>>(
        (this.data?.initialValue || []).map((r) =>
          this.priceLevelToFormGroup(r),
        ),
      ),
    });
  }

  onClickAddPriceLevelButton() {
    this.formArray.push(this.priceLevelToFormGroup(new PriceLevel()));
  }

  onClickRemovePriceLevelAtIndexButton(index: number) {
    this.formArray.removeAt(index);
  }

  onClickSubmitButton() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.ref.close(this.formGroup.value.levels?.map(PriceLevel.deserialize));
    }
  }

  ngAfterViewInit() {
    setTimeout(() => (this.animationBugWorkaroundState = true));
  }

  private priceLevelToFormGroup(p: PriceLevel): FormGroup<PriceLevel> {
    return this.fb.group<PriceLevel>({
      ...p,
      name: this.fb.control(p.name, Validators.required),
      price: this.fb.control(p.price, [Validators.required, Validators.min(0)]),
      attendeesSpace: this.fb.control(p.attendeesSpace, [
        Validators.required,
        Validators.min(1),
      ]),
      description: this.fb.control(p.description, Validators.required),
    });
  }
}

interface PriceLevelSettingFormModel {
  levels: FormArray<FormGroup<PriceLevel>>;
}
