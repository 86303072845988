// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './type';

export const environment: Environment = {
  production: false,
  clientId: 'tagea-cc',
  oidcConfigURL:
    'https://keycloak.dev.tremaze.de/auth/realms/tremaze-dev/.well-known/openid-configuration',
  basePath: 'https://api.dev.cloud.tagea.app/',
  enableServiceWorker: true,
  lightThemeLogoPath: null,
  darkThemeLogoPath: null,
  iconPath: null,
  productName: null,
  disableFreshChat: false,
  enableAllFeatures: false,
  stage: 'DEV',
  enableVitaAdvisorFor: '5819e5fe-d7b3-4d56-a340-594309a0537f',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
