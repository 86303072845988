import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FullTenantConfig } from './types';
import { TenantConfigService } from './tenant-config.service';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  ReplaySubject,
  shareReplay,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';

type InputType<T extends keyof FullTenantConfig = keyof FullTenantConfig> =
  | [T, FullTenantConfig[T]]
  | [T, FullTenantConfig[T], FullTenantConfig[T]]
  | [T, FullTenantConfig[T], FullTenantConfig[T], FullTenantConfig[T]];

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifTenantConfigValue]',
  standalone: true,
})
export class IfTenantConfigValueDirective implements OnDestroy, OnInit {
  private _input$ = new ReplaySubject<InputType>(1);
  private _destroyed$ = new Subject<void>();
  private _isEnabled$ = this._input$.pipe(
    mergeMap((input) =>
      this._service.config$.pipe(
        map((config) => {
          for (let i = 1; i < input.length; i++) {
            if (config[input[0]] === input[i]) {
              return true;
            }
          }
          return false;
        }),
      ),
    ),
    distinctUntilChanged(),
    takeUntil(this._destroyed$),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
  private _ifTenantConfigValue!: InputType;

  get ifTenantConfigValue(): InputType {
    return this._ifTenantConfigValue;
  }

  @Input()
  set ifTenantConfigValue(value: InputType) {
    this._ifTenantConfigValue = value;
    this._input$.next(value);
  }

  constructor(
    private readonly _service: TenantConfigService,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    this._isEnabled$
      .pipe(
        tap((isEnabled) => {
          if (isEnabled) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._input$.complete();
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
