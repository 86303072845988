import {
  CustomForm,
  CustomFormField,
  CustomFormSubmission,
  CustomMultiSelectFormField,
  CustomTextBoxFormField,
} from '@tremaze/shared/feature/custom-forms/types';
import { Placeholder } from '@tremaze/placeholder';
import { UserPlaceholderHelpers } from '@tremaze/shared/feature/user/types';

export abstract class CustomFormPlaceholderHelpers {
  static mapFormToPlaceholders(
    form: CustomForm,
    {
      includeFilledOutBy,
      includeFilledOutFor,
    }: {
      includeFilledOutBy?: boolean;
      includeFilledOutFor?: boolean;
    } = {},
  ): Placeholder[] {
    const formName = form.name;
    if (!formName) {
      return [];
    }
    const formId = formName.replace(/ /g, '_').toLowerCase();
    const fields: Placeholder[] = form.fields
      .filter((f) => f.fieldType !== 'FILE_UPLOAD')
      .map((f) => {
        const placeholder = `$${formId}.${f.label.replace(/ /g, '_').toLowerCase()}`;
        return {
          label: f.label,
          name: placeholder,
        };
      });

    if (includeFilledOutBy) {
      fields.push(
        ...UserPlaceholderHelpers.mapUserToPlaceholders(undefined, {
          labelPrefix: 'Ausgefüllt von: ',
          namePrefix: '$filled_out_by_',
        }),
      );
    }

    if (includeFilledOutFor) {
      fields.push(
        ...UserPlaceholderHelpers.mapUserToPlaceholders(undefined, {
          labelPrefix: 'Ausgefüllt für: ',
          namePrefix: '$filled_out_for_',
        }),
      );
    }

    return fields;
  }

  static mapFormSubmissionToPlaceholders(
    submission: CustomFormSubmission,
    {
      includeFilledOutBy,
      includeFilledOutFor,
    }: {
      includeFilledOutBy?: boolean;
      includeFilledOutFor?: boolean;
    } = {},
  ): Placeholder[] {
    function transformName(name: string): string {
      return name.replace(/ /g, '_').toLowerCase();
    }

    const formName = submission.formVersion.form?.name;

    if (!formName) {
      return [];
    }

    const formId = transformName(formName);
    const fields: Placeholder[] = submission.formVersion.fields
      .filter((f) => f.fieldType !== 'FILE_UPLOAD')
      .map((f) => {
        const placeholder = `$${formId}.${transformName(f.label)}`;
        return {
          label: f.label,
          name: placeholder,
          value: this._transformFilledOutValueToString(f),
        };
      });

    if (includeFilledOutBy) {
      fields.push(
        ...UserPlaceholderHelpers.mapUserToPlaceholders(
          submission.filledOutBy ?? undefined,
          {
            labelPrefix: 'Ausgefüllt von: ',
            namePrefix: '$filled_out_by_',
          },
        ),
      );
    }

    if (includeFilledOutFor) {
      fields.push(
        ...UserPlaceholderHelpers.mapUserToPlaceholders(
          submission.filledOutFor ?? undefined,
          {
            labelPrefix: 'Ausgefüllt für: ',
            namePrefix: '$filled_out_for_',
          },
        ),
      );
    }

    return fields;
  }

  private static _transformFilledOutValueToString(
    field: CustomFormField<unknown>,
  ): string {
    if (field instanceof CustomTextBoxFormField) {
      return field.filledOutValues?.value ?? '';
    }
    if (field instanceof CustomMultiSelectFormField) {
      return field.filledOutValues?.map((v) => v.value?.label).join(', ') ?? '';
    }
    return '';
  }
}
