@if (documentId$ | async; as id) {
    <tremaze-document-editor
      #documentEditor
      [documentName]="'test.docx'"
      [fileId]="id"
      style=" width: 100%; display:block "
      (requestClose)="onRequestClose()"
    >
    </tremaze-document-editor>
}
