import { User } from '@tremaze/shared/feature/user/types';
import { Meta } from '@tremaze/shared/models';
import { TremazeDate } from '@tremaze/shared/util-date';
import { CustomFormVersion } from './form-version';

export class CustomFormSubmission {
  constructor(
    readonly id: string,
    readonly meta: Meta,
    readonly formVersion: CustomFormVersion,
    readonly user: User | null,
    readonly completed: boolean,
    readonly completedAt?: TremazeDate,
    readonly filledOutFor?: User | null,
    readonly filledOutBy?: User | null,
  ) {}

  static deserialize(data: any): CustomFormSubmission {
    return new CustomFormSubmission(
      data.id,
      Meta.deserialize(data),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      CustomFormVersion.deserialize(data.formVersion)!,
      User.deserialize(data.user),
      data.completed === true,
      data.completedAt ? TremazeDate.deserialize(data.completedAt) : undefined,
      data.filledOutFor ? User.deserialize(data.filledOutFor) : undefined,
      data.filledOutBy ? User.deserialize(data.filledOutBy) : undefined,
    );
  }
}
