import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateSelectDialogComponent } from './select-dialog.component';
import { EventTemplateSelectService } from './select.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SharedFeatureEventTemplateFeatureDataTableModule } from '@tremaze/shared-feature-event-template-feature-data-table';
import { DataTableDisableFilterPersistenceDirective } from '@tremaze/shared/ui/data-table';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    SharedFeatureEventTemplateFeatureDataTableModule,
    DataTableDisableFilterPersistenceDirective,
  ],
  declarations: [EventTemplateSelectDialogComponent],
  providers: [EventTemplateSelectService],
})
export class SharedFeatureEventTemplateFeatureSelectModule {}
